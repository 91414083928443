<template>
  <div class="under-construction">
    <div class="left-panel">
      <div class="content">
        <img src="@/assets/school-logo.svg" alt="School Logo" class="school-logo">
        <h1>Sitio en Construcción</h1>
        <p>Estamos haciendo mantenimiento en nuestro sitio web. Volveremos pronto. ¡Gracias por esperar!</p>
        <div class="subscribe-form">
          <input type="email" v-model="email" placeholder="Tu correo electrónico" class="email-input">
          <button class="subscribe-button" @click="subscribe">AVÍSAME</button>
        </div>
        <p>¡Regístrate ahora para recibir una notificación temprana de nuestra fecha de lanzamiento!</p>
        <div class="not-found">
          <router-link to="/">Go to Home</router-link>
        </div>
      </div>
    </div>
    <div class="right-panel">
      <img src="@/assets/summer.jpg" alt="Under Construction Image" class="background-image">
      <div class="countdown">
        <div class="circle" v-for="(unit, index) in countdownUnits" :key="index">
          <svg>
            <circle :cx="radius" :cy="radius" :r="radius - 5" class="background-circle"></circle>
            <circle :cx="radius" :cy="radius" :r="radius - 5" :style="unit.style" :class="unit.class"></circle>
          </svg>
          <div class="time-container">
            <div class="time">{{ unit.value }}</div>
            <div class="label">{{ unit.label }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UnderConstruction',
  data() {
    return {
      email: '',
      radius: 50, // начальный радиус кругов для десктопной версии
      countdownUnits: [
        { label: 'Days', value: 0, max: 31, style: {}, class: 'green' },
        { label: 'Hours', value: 0, max: 24, style: {}, class: 'blue' },
        { label: 'Minutes', value: 0, max: 60, style: {}, class: 'purple' },
        { label: 'Seconds', value: 0, max: 60, style: {}, class: 'yellow' }
      ],
      targetDate: new Date('2024-09-02T00:00:00Z') // Целевая дата и время
    };
  },
  methods: {
    async subscribe() {
      if (!this.email) {
        alert('Please enter a valid email address.');
        return;
      }

      try {
        const response = await fetch('/api/subscribe', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email: this.email })
        });

        if (response.ok) {
          alert('Thank you for subscribing!');
          this.email = '';
        } else {
          alert('Subscription failed. Please try again later.');
        }
      } catch (error) {
        alert('An error occurred. Please try again later.');
      }
    },
    startCountdown() {
      setInterval(() => {
        const now = new Date().getTime();
        const distance = this.targetDate - now;

        if (distance < 0) {
          this.countdownUnits.forEach(unit => unit.value = 0);
          return;
        }

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        this.countdownUnits[0].value = days;
        this.countdownUnits[1].value = hours;
        this.countdownUnits[2].value = minutes;
        this.countdownUnits[3].value = seconds;

        this.updateCircleStyles();
      }, 1000);
    },
    updateCircleStyles() {
      this.countdownUnits.forEach(unit => {
        const circumference = 2 * Math.PI * (this.radius - 5);
        const percentage = (unit.value / unit.max) * circumference;
        unit.style = {
          strokeDasharray: `${percentage} ${circumference}`
        };
      });
    },
    updateRadius() {
      if (window.innerWidth <= 480) {
        this.radius = 30;
      } else if (window.innerWidth <= 768) {
        this.radius = 40;
      } else {
        this.radius = 50; // Устанавливаем постоянный радиус для десктопной версии
      }
    }
  },
  mounted() {
    this.startCountdown();
    this.updateRadius();
    window.addEventListener('resize', this.updateRadius);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateRadius);
  }
};
</script>

<style scoped>
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden; /* Убираем прокрутку */
}
.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 10vh;
  text-align: center;
}
.under-construction {
  display: flex;
  flex-direction: row;
  height: 100vh; /* Высота на весь экран */
  width: 100vw; /* Ширина на весь экран */
  position: fixed; /* Привязка к окну браузера */
  top: 0;
  left: 0;
}
.not-found a {
  color: #602B84;
  text-decoration: none;
  font-size: 1.2em;
  border: 1px solid #602B84;
  padding: 0.5em 1em;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.not-found a:hover {
  background-color: #602B84;
  color: white;
}
.left-panel, .right-panel {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-panel {
  background-color: #fff;
}

.content {
  text-align: center;
  padding: 20px;
}

.school-logo {
  width: 500px;
  margin-bottom: 20px;
}

h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

p {
  font-size: 1.2em;
  margin-bottom: 20px;
}

.subscribe-form {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.email-input {
  padding: 10px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px 0 0 5px;
  width: 300px;
}

.subscribe-button {
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #ff4d4d;
  border: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.subscribe-button:hover {
  background-color: #e63946;
}

.social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-icons a {
  color: #333;
  font-size: 1.5em;
  margin: 0 5px;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #ff4d4d;
}

.right-panel {
  position: relative;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.countdown {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center; /* Центрируем круги */
  gap: 30px; /* Устанавливаем начальный gap для десктопной версии */
}

.circle {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.circle svg {
  width: 100px; /* Устанавливаем фиксированный размер для десктопной версии */
  height: 100px;
  transform: rotate(-90deg);
}

circle {
  fill: none;
  stroke: #ddd;
  stroke-width: 5;
}

.background-circle {
  stroke: #ddd;
}

circle:nth-child(2) {
  stroke-width: 5;
  transition: stroke-dasharray 1s linear;
}

circle.green {
  stroke: #66ff66;
}

circle.blue {
  stroke: #3399ff;
}

circle.purple {
  stroke: #cc33ff;
}

circle.yellow {
  stroke: #ffcc00;
}

.time-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Центрируем текст внутри контейнера */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.time {
  font-size: 1.2em;
  color: #fff;
}

.label {
  font-size: 0.8em;
  color: #fff;
}
@media (max-width: 1044px) {
  .under-construction {
    flex-direction: column;
  }

  .left-panel, .right-panel {
    flex: none;
    width: 100%;
    height: 50%;
  }

  .content {
    padding: 10px;
  }

  .school-logo {
    width: 300px;
  }
  .time-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Центрируем текст внутри контейнера */
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  h1 {
    font-size: 2em;
  }

  p {
    font-size: 1em;
  }

  .email-input {
    width: 200px;
  }

  .circle {
    width: 100px;
    height: 100px;
  }

  .countdown {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center; /* Центрируем круги */
    gap:30px; /* Устанавливаем начальный gap для десктопной версии */
  }
}
@media (max-width: 768px) {
  .background-image {
    height: 90%;
  }
  .under-construction {
    flex-direction: column;
  }

  .left-panel, .right-panel {
    flex: none;
    width: 100%;
    height: 55%;
  }

  .content {
    padding: 10px;
  }

  .school-logo {
    width: 300px;
  }
  .time-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Центрируем текст внутри контейнера */
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  h1 {
    font-size: 2em;
  }

  p {
    font-size: 1em;
  }

  .email-input {
    width: 200px;
  }

  .circle {
    width: 80px;
    height: 80px;
  }

  .countdown {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center; /* Центрируем круги */
    gap: 10px; /* Устанавливаем начальный gap для десктопной версии */
  }
}

@media (max-width: 480px) {
  .background-image {
    height: 90%;
  }
  .under-construction {
    flex-direction: column;
  }

  .content {
    padding: 5px;
  }

  .school-logo {
    width: 300px;
  }

  h1 {
    font-size: 1.5em;
  }

  p {
    font-size: 0.9em;
  }

  .email-input {
    width: 150px;
  }
  
  .time-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Центрируем текст внутри контейнера */
    position: absolute;
    top: 75%;
    left: 45%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .subscribe-button {
    padding: 8px 15px;
    font-size: 0.9em;
  }

  .social-icons a {
    font-size: 1.2em;
  }

  .circle {
    width: 70px;
    height: 70px;
  }

  .countdown {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center; /* Центрируем круги */
    gap: 10px; /* Устанавливаем начальный gap для десктопной версии */
  }

  .time {
    font-size: 1em;
  }

  .label {
    font-size: 0.7em;
  }
}
</style>

