<template>
  <div id="app" :class="{ 'full-screen': isUnderConstruction || showEnquireModal }">
    <LoadingScreen v-if="loading" @loading-complete="loading = false" />
    <div v-else>
      <main>
        <router-view></router-view>
      </main>
      <AppFooter
        v-if="!isUnderConstruction && showFooter"
        @open-chat="openChat"
        @open-enquire="openEnquire"
      />
      <LanguageWidget
        v-if="isDesktop && !isUnderConstruction && showFooter"
        @open-chat="openChat"
        @open-enquire="openEnquire"
      />
      <ChatWidget ref="chatWidget" />
      <!-- Полноэкранная форма запроса -->
      <div v-if="showEnquireModal" class="modal-overlay" @click="closeEnquireForm">
        <div class="modal-content" @click.stop>
          <div class="modal-header">
            <h2>{{ $t('subscribeNewsletters') }}</h2>
            <div class="close-button" @click="closeEnquireForm">
              <div class="close-line close-line-1"></div>
              <div class="close-line close-line-2"></div>
            </div>
          </div>
          <div class="modal-body">
            <img
              src="@/assets/Spanglish_Logo-Vertical-Color_RGB.png"
              alt="Spanglish Logo"
              class="modal-logo"
            />
            <div class="modal-form">
              <p>{{ $t('latestUpdates') }}</p>
              <form @submit.prevent="submitEnquireForm">
                <div class="form-group">
                  <input
                    type="email"
                    v-model="enquireEmail"
                    placeholder="Your Email Address"
                    required
                  />
                </div>
                <button type="submit" class="subscribe-button">{{ $t('Subscribe') }}</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import LoadingScreen from './components/LoadingScreen.vue';
import AppFooter from './components/AppFooter.vue';
import LanguageWidget from './components/LanguageWidget.vue';
import ChatWidget from './components/ChatWidget.vue';
import axios from 'axios'; // Подключаем axios для отправки запросов

export default {
  components: {
    LoadingScreen,
    AppFooter,
    LanguageWidget,
    ChatWidget
  },
  data() {
    return {
      loading: true,
      isDesktop: window.innerWidth > 1024,
      showEnquireModal: false, // состояние модального окна
      enquireEmail: '' // email для формы подписки
    };
  },
  computed: {
    ...mapState(['showFooter']),
    isUnderConstruction() {
      return this.$route.name === 'UnderConstruction';
    }
  },
  watch: {
    '$route.path'() {
    }
  },
  mounted() {
    window.addEventListener('resize', this.checkIsDesktop);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkIsDesktop);
  },
  methods: {
    checkIsDesktop() {
      this.isDesktop = window.innerWidth > 1024;
    },
    openChat() {
      this.$refs.chatWidget.openChat();
    },
    openEnquire() {
      this.showEnquireModal = true; // Открываем модальное окно
    },
    closeEnquireForm() {
      this.showEnquireModal = false; // Закрываем модальное окно
    },
    submitEnquireForm() {
      // Отправка формы подписки
      axios
        .post('/api/subscribe', { email: this.enquireEmail })
        .then(response => {
          console.log(response.data);
          alert('Subscription successful!');
          this.showEnquireModal = false;
          this.enquireEmail = '';
        })
        .catch(error => {
          console.error(error);
          alert('Subscription failed.');
        });
    }
  }
};
</script>

<style>
body {
  font-family: 'Mulish', sans-serif;
  margin: 0;
  padding: 0;
  color: #333;
  overflow-x: hidden;
  

}

#app.full-screen {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

#app {
  height: 100vh;
}

main {
  padding: 0; /* Убираем padding чтобы фон геро-секции занял всю высоту */
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Полупрозрачный фон */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.modal-content {
  background: white;
  padding: 40px 20px;
  border-radius: 20px;
  max-width: 600px;
  width: 90%;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-logo {
  width: 200px;
  margin-right: 20px;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-form {
  flex: 1;
  width: 100%;
}

.modal-form p {
  margin-bottom: 15px;
  font-size: 20px;
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
  position: relative;
  width: 100%;
}

.form-group input {
  width: 100%;
  padding: 10px 15px;
  border-radius: 30px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 16px;
  background-color: white;
  position: relative;
  z-index: 1;
}

.subscribe-button {
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  background-image: linear-gradient(to right, #ee322e, #feda3a);
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.subscribe-button:hover {
  background-image: linear-gradient(to right, #feda3a, #ee322e);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.close-line {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 2px;
  background: #ee322e;
  transform: translate(-50%, -50%) rotate(45deg);
}

.close-line-2 {
  background: #602b84;
  transform: translate(-50%, -50%) rotate(-45deg);
}

</style>
