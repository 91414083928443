import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import ru from './locales/ru.json';
import de from './locales/de.json';
import zh from './locales/zh.json';
import es from './locales/es.json';

const userLocale = navigator.language.split('-')[0] || navigator.userLanguage.split('-')[0];
const availableLocales = ['en', 'ru', 'de', 'zh', 'es'];

const locale = availableLocales.includes(userLocale) ? userLocale : 'en';

const i18n = createI18n({
  locale: locale,
  fallbackLocale: 'en',
  messages: {
    en,
    ru,
    de,
    zh,
    es
  },
});

export default i18n;


