<template>
  <div>
    <BookingContent />
  </div>
</template>

<script>
import BookingContent from '../components/BookingContent.vue';

export default {
  name: 'BookingPage',
  components: {
    BookingContent
  }
};
</script>

<style scoped>
/* ваш стиль */
</style>
