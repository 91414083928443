<template>
  <div class="main-container ">
    <div class="hero-section">
      <AppHeader />
      <div class="hero-content">
        <h1>
          <span v-html="$t('headerTitle')"></span>
        </h1>
        <div class="call-to-action">
          <p>{{ $t('bookACall') }}</p>
          <div class="input-container">
            <div class="custom-select-wrapper" @click="toggleDropdown">
              <div class="selected-option">
                <img :src="selectedFlag" alt="Flag" class="flag" /> 
              </div>
              <div v-if="isOpen" class="options">
                <div
                  v-for="(country, index) in countries"
                  :key="country.code"
                  @click="selectCountry(country)"
                  class="option"
                  :class="{ 'with-divider': index !== countries.length - 1 }"
                >
                  <img :src="country.flag" alt="Flag" class="flag" />
                </div>
              </div>
            </div>
            <input
              v-model="userPhoneNumber"
              type="tel"
              :placeholder="$t('phonePlaceholder')"
              pattern="[0-9]*"
              minlength="1"
              @input="validatePhoneNumber"
              required
            />
            <button @click="sendPhoneNumber"><span>&rarr;</span></button>
          </div>
        </div>
      </div>
    </div>
    <div class="content-container">
      <section class="programs-section">
        <h2>{{ $t('programsTitle') }}</h2>
        <div class="buttons-container">
          <button class="cta-button" @click="navigateToCourses">{{ $t('discoverPrograms') }}</button>
          <button class="cta-button">{{ $t('requestBrochure') }}</button>
        </div>
        <div class="courses-container" v-if="isDesktop">
          <div class="course-card" @click="openCourseModal('spanishCourses')">
            <img src="@/assets/spanish-courses.jpg" :alt="$t('spanishCourses')" />
            <div class="course-info">
              <h3>{{ $t('spanishCourses') }}</h3>
              <button class="arrow-button">&rarr;</button>
            </div>
          </div>
          <div class="course-card" @click="openCourseModal('englishCourses')">
            <img src="@/assets/english-courses.jpg" :alt="$t('englishCourses')" />
            <div class="course-info">
              <h3>{{ $t('englishCourses') }}</h3>
              <button class="arrow-button">&rarr;</button>
            </div>
          </div>
          <div class="course-card" @click="openCourseModal('coursesAbroad')">
            <img src="@/assets/courses-abroad.jpg" :alt="$t('coursesAbroad')" />
            <div class="course-info">
              <h3>{{ $t('coursesAbroad') }}</h3>
              <button class="arrow-button">&rarr;</button>
            </div>
          </div>
        </div>
        <swiper v-else :options="swiperOptions" class="courses-swiper">
          <swiper-slide>
            <div class="course-card" @click="openCourseModal('spanishCourses')">
              <img src="@/assets/spanish-courses.jpg" :alt="$t('spanishCourses')" />
              <div class="course-info">
                <h3>{{ $t('spanishCourses') }}</h3>
                <button class="arrow-button">&rarr;</button>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="course-card" @click="openCourseModal('englishCourses')">
              <img src="@/assets/english-courses.jpg" :alt="$t('englishCourses')" />
              <div class="course-info">
                <h3>{{ $t('englishCourses') }}</h3>
                <button class="arrow-button">&rarr;</button>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="course-card" @click="openCourseModal('coursesAbroad')">
              <img src="@/assets/courses-abroad.jpg" :alt="$t('coursesAbroad')" />
              <div class="course-info">
                <h3>{{ $t('coursesAbroad') }}</h3>
                <button class="arrow-button">&rarr;</button>
              </div>
            </div>
          </swiper-slide>
          <div class="swiper-container">
            <img src="@/assets/swipe.gif" alt="Swiper Animation" class="swiper-gif" />
          </div>
        </swiper>
      </section>
      <section class="next-courses-section">
        <h2>{{ $t('nextCoursesTitle') }}</h2>
        <ul class="course-list">
          <li class="course-item">
            <span class="course-name">{{ $t('intensiveSpanishCourse') }}</span>
            <button class="join-button" @click="navigateToBooking">{{ $t('joinNow') }}</button>
            <span class="course-date">01/09</span>
          </li>
          <li class="course-item">
            <span class="course-name">{{ $t('deleExamPreparation') }}</span>
            <button class="join-button" @click="navigateToBooking">{{ $t('joinNow') }}</button>
            <span class="course-date">01/09</span>
          </li>
          <li class="course-item">
            <span class="course-name">{{ $t('generalEnglishCourse') }}</span>
            <button class="join-button" @click="navigateToBooking">{{ $t('joinNow') }}</button>
            <span class="course-date">01/09</span>
          </li>
          <li class="course-item">
            <span class="course-name">{{ $t('examPreparationCourse') }}</span>
            <button class="join-button" @click="navigateToBooking">{{ $t('joinNow') }}</button>
            <span class="course-date">01/09</span>
          </li>
        </ul>
      </section>
      <div class="culture-section">
        <div class="culture-content">
          <h2>{{ $t('cultureWorkshopsActivities') }}</h2>
          <div class="call-to-action">
            <p>{{ $t('subscribeNewsletter') }}</p>
            <div class="input-container">
              <input type="email" v-model="email" :placeholder="$t('yourEmailAddress')" class="email-input" />
              <button class="subscribe_bot-button" @click="subscribe"><span>&rarr;</span></button>
            </div>
          </div>
        </div>
      </div>
      <section class="newsletter-section">
        <div class="newsletter-content">
          <h2>{{ $t('subscribeNewsletters') }}</h2>
          <p>{{ $t('latestUpdates') }}</p>
        </div>
        <div class="newsletter-input-container">
          <input type="email" v-model="email" :placeholder="$t('yourEmailAddress')" class="email-input" />
          <button class="subscribe_bot-button" @click="subscribe"><span>&rarr;</span></button>
        </div>
        <h2></h2>
        <h2></h2>
      </section>
    </div>
    <button class="scroll-to-top" @click="scrollToTop">
      &uarr;
    </button>

    <!-- Модальное окно с информацией о курсе -->
    <div v-if="showCourseModal" class="modal-overlay" @click="closeCourseModal">
      <div class="modal-content" @click.stop>
        <div class="modal-header">
          <h2>{{ currentCourseTitle }}</h2>
          <div class="close-button" @click="closeCourseModal">
            <div class="close-line close-line-1"></div>
            <div class="close-line close-line-2"></div>
          </div>
        </div>
        <div class="modal-body">
          <!-- Контроль отображения изображения и описания -->
          <div v-if="!hideImageAndDescription">
            <img :src="currentCourseImage" :alt="currentCourseTitle" class="modal-course-image" />
            <p>{{ currentCourseDescription }}</p>
            <!-- Селектор выбора курса -->
            <div v-if="currentCourseOptions.length > 0" class="course-selector">
              <label for="course-select">{{ $t('Selectacourse') }}</label>
              <select id="course-select" v-model="selectedCourseOption">
                <option v-for="option in currentCourseOptions" :key="option.title" :value="option.title">
                  {{ option.title }}
                </option>
              </select>
              <!-- Описание выбранного курса -->
              <p v-if="selectedCourseOption">
                {{ currentCourseOptions.find(option => option.title === selectedCourseOption).description }}
              </p>
            </div>
          </div>
          <div class="course-buttons">
            <button class="cta-button" @click="togglePhoneNumberInput">{{ $t('Scheduleacall') }}</button>
            <button class="cta-button" @click="bookCourseOnline">{{ $t('Bookacourseonline') }}</button>
          </div>
          <div v-if="showPhoneNumberInput" class="call-to-action-mod">
            <p>{{ $t('bookACall') }}</p>
            <div class="input-container-name">
              <input v-model="userName" type="text" :placeholder="$t('Yourname')" required />
            </div>
            <div class="input-container">
              <div class="custom-select-wrapper" @click="toggleDropdown">
                <div class="selected-option">
                  <img :src="selectedFlag" alt="Flag" class="flag" /> 
                </div>
                <div v-if="isOpen" class="options">
                  <div
                    v-for="(country, index) in countries"
                    :key="country.code"
                    @click="selectCountry(country)"
                    class="option"
                    :class="{ 'with-divider': index !== countries.length - 1 }"
                  >
                    <img :src="country.flag" alt="Flag" class="flag" />
                  </div>
                </div>
              </div>
              <input
                v-model="userPhoneNumber"
                type="tel"
                :placeholder="$t('phonePlaceholder')"
                :maxlength="getPhoneNumberMaxLength(selectedCountryCode)"
                pattern="[0-9]*"
                @input="validatePhoneNumber"
                required
              />
              <button @click="sendPhoneNumber"><span>&rarr;</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import AppHeader from "./AppHeader.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";

export default {
  components: {
    AppHeader,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      isOpen: false,
      selectedCountryName: "",
      selectedCountryCode: "",
      selectedFlag: require("@/assets/unknown-flag.svg"),
      userPhoneNumber: "",
      userName: "",
      email: "",
      countries: [
        { code: "+1", name: "US", flag: require("@/assets/us-flag.png") },
        { code: "+44", name: "UK", flag: require("@/assets/uk-flag.png") },
        { code: "+33", name: "France", flag: require("@/assets/fr-flag.png") },
        { code: "+49", name: "Germany", flag: require("@/assets/de-flag.png") },
        { code: "+7", name: "Russia", flag: require("@/assets/ru-flag.png") },
        { code: "+43", name: "Austria", flag: require("@/assets/at-flag.png") },
        { code: "+32", name: "Belgium", flag: require("@/assets/be-flag.png") },
        { code: "+359", name: "Bulgaria", flag: require("@/assets/bg-flag.png") },
        { code: "+385", name: "Croatia", flag: require("@/assets/hr-flag.png") },
        { code: "+357", name: "Cyprus", flag: require("@/assets/cy-flag.png") },
        { code: "+420", name: "Czech Republic", flag: require("@/assets/cz-flag.png") },
        { code: "+45", name: "Denmark", flag: require("@/assets/dk-flag.png") },
        { code: "+372", name: "Estonia", flag: require("@/assets/ee-flag.png") },
        { code: "+358", name: "Finland", flag: require("@/assets/fi-flag.png") },
        { code: "+30", name: "Greece", flag: require("@/assets/gr-flag.png") },
        { code: "+36", name: "Hungary", flag: require("@/assets/hu-flag.png") },
        { code: "+354", name: "Iceland", flag: require("@/assets/is-flag.png") },
        { code: "+353", name: "Ireland", flag: require("@/assets/ie-flag.png") },
        { code: "+39", name: "Italy", flag: require("@/assets/it-flag.png") },
        { code: "+371", name: "Latvia", flag: require("@/assets/lv-flag.png") },
        { code: "+370", name: "Lithuania", flag: require("@/assets/lt-flag.png") },
        { code: "+352", name: "Luxembourg", flag: require("@/assets/lu-flag.png") },
        { code: "+356", name: "Malta", flag: require("@/assets/mt-flag.png") },
        { code: "+31", name: "Netherlands", flag: require("@/assets/nl-flag.png") },
        { code: "+47", name: "Norway", flag: require("@/assets/no-flag.png") },
        { code: "+48", name: "Poland", flag: require("@/assets/pl-flag.png") },
        { code: "+351", name: "Portugal", flag: require("@/assets/pt-flag.png") },
        { code: "+40", name: "Romania", flag: require("@/assets/ro-flag.png") },
        { code: "+421", name: "Slovakia", flag: require("@/assets/sk-flag.png") },
        { code: "+386", name: "Slovenia", flag: require("@/assets/si-flag.png") },
        { code: "+34", name: "Spain", flag: require("@/assets/es-flag.png") },
        { code: "+46", name: "Sweden", flag: require("@/assets/se-flag.png") },
        { code: "+41", name: "Switzerland", flag: require("@/assets/ch-flag.png") },
        { code: "+90", name: "Turkey", flag: require("@/assets/tr-flag.png") }
      ],
      swiperOptions: {
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      showCourseModal: false, // State for course modal visibility
      currentCourseTitle: "", // Currently selected course title
      currentCourseImage: "", // Currently selected course image
      currentCourseDescription: "", // Currently selected course description
      hideImageAndDescription: false, // State for hiding image and description
      selectedCourseOption: "", // Selected course option
      showPhoneNumberInput: false, // State for phone number input visibility
    };
  },
  computed: {
    isDesktop() {
      return window.innerWidth >= 1024;
    },
    currentCourseOptions() {
      if (this.currentCourseTitle === this.$t("spanishCourses")) {
        return [
          {
            title: this.$t("SpaincourseOptions.semiIntensive.title"),
            description: this.$t(
              "SpaincourseOptions.semiIntensive.description"
            ),
          },
          {
            title: this.$t("SpaincourseOptions.partTime.title"),
            description: this.$t("SpaincourseOptions.partTime.description"),
          },
          {
            title: this.$t("SpaincourseOptions.specializedClasses.title"),
            description: this.$t(
              "SpaincourseOptions.specializedClasses.description"
            ),
          },
          {
            title: this.$t("SpaincourseOptions.privateLessons.title"),
            description: this.$t("SpaincourseOptions.privateLessons.description"),
          },
          {
            title: this.$t("SpaincourseOptions.delePreparation.title"),
            description: this.$t("SpaincourseOptions.delePreparation.description"),
          },
          {
            title: this.$t("SpaincourseOptions.kidsAndTeens.title"),
            description: this.$t("SpaincourseOptions.kidsAndTeens.description"),
          },
          {
            title: this.$t("SpaincourseOptions.urbanCamps.title"),
            description: this.$t("SpaincourseOptions.urbanCamps.description"),
          },
          {
            title: this.$t("SpaincourseOptions.theaterWorkshops.title"),
            description: this.$t(
              "SpaincourseOptions.theaterWorkshops.description"
            ),
          },
          {
            title: this.$t("SpaincourseOptions.nativeSpeakerTheater.title"),
            description: this.$t(
              "SpaincourseOptions.nativeSpeakerTheater.description"
            ),
          },
          {
            title: this.$t("SpaincourseOptions.creativeWriting.title"),
            description: this.$t(
              "SpaincourseOptions.creativeWriting.description"
            ),
          },
        ];
      } else if (this.currentCourseTitle === this.$t("englishCourses")) {
        return [
          {
            title: this.$t("EngcourseOptions.generalEnglishCourse.title"),
            description: this.$t(
              "EngcourseOptions.generalEnglishCourse.description"
            ),
          },
          {
            title: this.$t("EngcourseOptions.cursoCambridge.title"),
            description: this.$t("EngcourseOptions.cursoCambridge.description"),
          },
        ];
      } else if (this.currentCourseTitle === this.$t("coursesAbroad")) {
        return [
          {
            title: this.$t("AbroadcourseOptions.generalEnglish.title"),
            description: this.$t("AbroadcourseOptions.generalEnglish.description"),
          },
          {
            title: this.$t("AbroadcourseOptions.intensiveEnglish.title"),
            description: this.$t("AbroadcourseOptions.intensiveEnglish.description"),
          },
          {
            title: this.$t("AbroadcourseOptions.summerJuniorCourses.title"),
            description: this.$t("AbroadcourseOptions.summerJuniorCourses.description"),
          },
        ];
      }
      return [];
    },
  },
  watch: {
    isDesktop(newVal) {
      if (newVal) {
        this.$refs.swiper.swiper.destroy(true, true);
      } else {
        this.$nextTick(() => {
          this.$refs.swiper.swiper.init();
        });
      }
    },
  },
  mounted() {
    window.addEventListener("resize", this.checkViewport);
    document.addEventListener('click', this.handleClickOutside);
    this.checkViewport();
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.checkViewport);
    document.removeEventListener('click', this.handleClickOutside);
  },
  methods: {   
    handleClickOutside(event) {
      const dropdown = this.$el.querySelector('.custom-select-wrapper');
      if (dropdown && !dropdown.contains(event.target)) {
        this.isOpen = false; // Закрываем выпадающий список, если клик был вне его области
      }
    },
    validatePhoneNumber() {
      this.userPhoneNumber = this.userPhoneNumber.replace(/\D/g, ''); // Удаляет все символы, кроме цифр
    },
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectCountry(country) {
      this.selectedCountryName = country.name;
      this.selectedFlag = country.flag;
      this.selectedCountryCode = country.code; // Обновляем код страны
      this.isOpen = false; // Закрываем выпадающий список после выбора
    },

    navigateToCourses() {
      window.location.href = 'https://elspanglish.es/cursos';
    },
    navigateToBooking() {
      window.location.href = 'https://elspanglish.es/booking';
    },
    async subscribe() {
      if (!this.email) {
        alert("Please enter a valid email address.");
        return;
      }

      try {
        const response = await fetch("/api/subscribe", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: this.email }),
        });

        if (response.ok) {
          alert("Thank you for subscribing!");
          this.email = "";
        } else {
          alert("Subscription failed. Please try again later.");
        }
      } catch (error) {
        alert("An error occurred. Please try again later.");
      }
    },
    checkViewport() {
      this.isDesktop = window.innerWidth >= 1024;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    openCourseModal(courseTitle) {
      this.showCourseModal = true;
      this.hideImageAndDescription = false; // Reset state when opening the modal
      this.currentCourseTitle = this.$t(courseTitle);
      if (courseTitle === "spanishCourses") {
        this.currentCourseImage = require("@/assets/spanish-courses.jpg");
        this.currentCourseDescription = this.$t("spanishCoursesDesc");
      } else if (courseTitle === "englishCourses") {
        this.currentCourseImage = require("@/assets/english-courses.jpg");
        this.currentCourseDescription = this.$t("englishCoursesDesc");
      } else if (courseTitle === "coursesAbroad") {
        this.currentCourseImage = require("@/assets/courses-abroad.jpg");
        this.currentCourseDescription = this.$t("coursesAbroadDesc");
      }
    },
    closeCourseModal() {
      this.showCourseModal = false;
      this.selectedCourseOption = ""; // Reset the course selector when the modal is closed
    },
    togglePhoneNumberInput() {
      this.showPhoneNumberInput = !this.showPhoneNumberInput;
      this.hideImageAndDescription = this.showPhoneNumberInput; // Toggle visibility of image and text
    },
   async sendPhoneNumber() {
     if (this.selectedFlag === require("@/assets/unknown-flag.svg")) {
        alert("Please select your country flag.");
        return;
      }
      // Объединяем код страны и номер телефона
      const phoneNumber = `${this.selectedCountryCode} ${this.userPhoneNumber}`;
      
      // Проверка на минимальную длину номера телефона
      if (this.userPhoneNumber.length < 1) {
        alert(this.$t('enterAtLeastOneDigit'));
        return;
      }
    
      try {
        // Отправляем телефон с кодом страны
        const response = await fetch("/api/sendPhoneNumber", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phoneNumber,  // Здесь отправляем телефон с кодом страны
            name: this.userName,
            course: this.selectedCourseOption,
          }),
        });
    
        if (response.ok) {
          alert(this.$t('phoneNumberSentSuccess'));
          this.userPhoneNumber = "";
          this.userName = "";
          this.selectedCourseOption = "";
        } else {
          const errorText = await response.text();
          console.error(this.$t('phoneNumberSendingError'), errorText);
          alert(this.$t('phoneNumberSendingErrorTryLater'));
        }
      } catch (error) {
        console.error(this.$t('phoneNumberSendingError'), error);
        alert(this.$t('phoneNumberSendingErrorTryLater'));
      }
    },
    bookCourseOnline() {
      alert(this.$t('Bookacourseonline'));
      // Add logic for booking a course here
    },
    getPhoneNumberMaxLength(countryCode) {
      // Define maximum phone number length based on the country code
      const maxLengthMap = {
        "+1": 10, // USA
        "+44": 10, // UK
        "+33": 9, // France
        "+49": 10, // Germany
        "+7": 10, // Russia
        "+34": 9, // Spain
        // Add other countries as needed
      };
      return maxLengthMap[countryCode] || 10; // Default to 10
    },
  },
};
</script>



<style scoped>
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'DM Sans', sans-serif;
  
}

.main-container {
  width: 100%;
  overflow: hidden;
}

.custom-select-wrapper {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.selected-option {
  display: flex;
  align-items: center;
  padding: 13px;
  border-top-left-radius: 30px;
  background-color: white;
  height: 14px;
  border-bottom-left-radius: 30px;
  border: 1px solid #ee322e;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  border: 1px solid #ee322e;
  max-height: 170px;
  overflow-y: auto;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}

.option {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.with-divider {
  border-bottom: 1px solid #ddd; /* Полоса между странами */
}

.flag {
  width: 20px;
  height: 20px;
}

.hero-section {
  width: 100%;
  height: 100vh;
  background: url('@/assets/1_home_back.jpg') no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  margin: 0;
  padding: 0 180px;
}

.hero-section header {
  position: fixed;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.hero-content {
  background-color: rgba(238, 50, 46, 0.7);
  padding: 50px;
  border-radius: 50px;
  text-align: left;
  z-index: 1;
  font-family: 'DM Sans', sans-serif;
  margin-left: 50px;
  height: 300px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero-content h1 {
  color: white;
  font-size: 36px;
  line-height: 1.2;
  font-weight: 400; /* DM Sans Regular */
  white-space: pre-wrap;
}

.call-to-action {
  margin-top: 20px;
}

.call-to-action p {
  color: white;
  font-size: 18px;
  font-weight: 400; /* DM Sans Regular */
}

.input-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.input-container select.country-code {
  padding: 10px;
  border: none;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: white;
  cursor: pointer;
  height: 40px;
  width: 90px;
}

.input-container input {
  padding: 10px;
  border: none;
  border-radius: 0;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  flex: 1;
  height: 20px;
}

.input-container button {
  padding: 10px;
  background-color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.input-container button span {
  font-size: 20px;
  color: #EE322E;
}

.content-container {
  padding: 40px 0 0 0;
}

.programs-section {
  text-align: center;
  margin-bottom: 40px;
}

.programs-section h2 {
  white-space: pre-wrap;
  font-size: 36px;
  font-family: 'DM Sans', sans-serif;
  font-weight: 500; /* DM Sans Bold */
  margin-bottom: 40px;
}

.buttons-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
}

.cta-button {
  padding: 15px 30px;
  border: 2px solid #333;
  border-radius: 50px;
  background: none;
  font-size: 20px;
  font-family: 'DM Sans', sans-serif;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cta-button:hover {
  background: #333;
  color: white;
}

.courses-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
}

.course-card {
  width: 320px;
  border-radius: 40px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.course-card img {
  width: 100%;
  height: 350px;
  object-fit: cover;
  box-shadow: 1px 1px 20px #EE322E;
}

.course-card:hover {
  transform: translateY(-10px);
}

.course-info {
  background: rgba(0, 0, 0, 0.6);
  color: #FFD700;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.course-info h3 {
  font-size: 18px;
  margin: 0;
  font-weight: 500; /* DM Sans Bold */
  font-family: 'DM Sans', sans-serif;
}

.swiper-container {
  display: flex;
  justify-content: flex-end;
}

.swiper-gif {
  width: 30%;
  height: auto;
}

.arrow-button {
  background: none;
  border: 2px solid #FFD700;
  border-radius: 50%;
  color: #FFD700;
  cursor: pointer;
  font-size: 16px;
  font-family: 'DM Sans', sans-serif;
  padding: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
}

.arrow-button:hover {
  background: #FFD700;
  color: white;
}

.next-courses-section {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 40px;
}

.next-courses-section h2 {
  font-size: 36px;
  font-weight: 500; /* DM Sans Bold */
  font-family: 'DM Sans', sans-serif;
  margin-bottom: 20px;
}

.course-list {
  list-style: none;
  padding: 0;
  margin: 0;
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.course-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-top: 1px solid #333;
}

.course-item:last-child {
  border-bottom: 1px solid #333;
}

.course-name {
  font-size: 24px;
  font-weight: 400; /* DM Sans Regular */
  font-family: 'DM Sans', sans-serif;
  flex: 1;
  text-align: left;
}

.join-button {
  background: none;
  border: 2px solid #333;
  border-radius: 30px;
  padding: 5px 20px;
  font-size: 14px;
  cursor: pointer;
  font-weight: bold;
  font-family: 'DM Sans', sans-serif;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.join-button:hover {
  background: #333;
  color: white;
}

.course-date {
  font-size: 24px;
  margin-left: 10px;
  text-align: right;
  font-style: italic; /* DM Sans Italic */
  font-family: 'DM Sans';
}

.course-item > * {
  flex: 1;
  text-align: left;
}

.join-button {
  flex: 0 0 150px;
  text-align: center;
}

.course-date {
  flex: 0 0 80px;
  text-align: right;
}

.culture-section {
  width: 100%;
  height: 100vh;
  background: url('@/assets/2_home_back.jpg') no-repeat center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  margin: 0;
  padding: 0;
}

.culture-content {
  background-color: rgba(74, 20, 140, 0.7);
  text-align: left;
  color: white;
  padding: 50px;
  border-radius: 50px;
  z-index: 1;
  font-family: 'DM Sans', sans-serif;
  margin-right: 100px;
  height: 300px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.culture-content h2 {
  font-size: 36px;
  margin: 0;
  line-height: 1.2;
  font-weight: 500; /* DM Sans Bold */
  font-family: 'DM Sans', sans-serif;
  white-space: pre-wrap;
}

.culture-content .call-to-action {
  margin-top: 20px;
}

.culture-content .call-to-action p {
  font-size: 18px;
  font-weight: 400; /* DM Sans Regular */
  font-family: 'DM Sans', sans-serif;
}

.culture-content .input-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.culture-content .input-container input {
  padding: 10px;
  border: none;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  flex: 1;
}

.culture-content .input-container button {
  padding: 10px;
  background-color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.culture-content .input-container button span {
  font-size: 20px;
  color: #4A148C;
  font-family: 'DM Sans', sans-serif;
}

.newsletter-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(240, 244, 248, 0.7);
  padding: 40px;
  border-radius: 10px;
  width: 80%;
  margin: 0 auto;
}

.newsletter-content {
  flex: 1;
  padding-right: 20px;
}

.newsletter-input-container {
  flex: 2;
  display: flex;
  align-items: center;
}

.newsletter-input-container input {
  padding: 6px;
  border: 2px solid #333;
  border-radius: 50px 50px 50px 50px;
  flex: 1;
  height: 40px;
  padding-left: 25px;
  font-size: 16px;
  font-family: 'DM Sans', sans-serif;
}

.newsletter-input-container button {
  padding: 15px;
  border-radius: 50px 50px 50px 50px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
}

.newsletter-input-container button span {
  font-size: 20px;
  color: #333;
  font-family: 'DM Sans', sans-serif;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #EE322E;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 24px;
  font-family: 'DM Sans', sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

.scroll-to-top:hover {
  background-color: #d42a26;
}
.cta-button {
  padding: 10px 20px;
  border: 2px solid #EE322E;
  border-radius: 50px;
  background: none;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #EE322E;
}

.cta-button:hover {
  background:  #EE322E;
  color: white;
}

.info-button {
  padding: 10px 20px;
  border: 2px solid #EE322E;
  border-radius: 25px;
  background: #EE322E;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;
}

.info-button:hover {
  background: none;
  border-color:  #EE322E;
  color: #EE322E;
}
.cta-bot-button {
  padding: 10px 20px;
  border: 2px solid #EE322E;
  border-radius: 25px;
  background: #EE322E;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;
  white-space: nowrap;
}

.cta-bot-button:hover {
  background: none;
  color: white;
  border: 2px solid #EE322E;
}

.info-bot-button {
  padding: 10px 20px;
  border: 2px solid #FEDA3A;
  border-radius: 25px;
  background:none;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #FEDA3A;
  white-space: nowrap;
}

.info-bot-button:hover {
  background: #FEDA3A;
  border-color: #333;
  color:#333;
}


/* Стили для модального окна */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  overflow-y: auto; /* Добавляем прокрутку для overlay */
}
.modal-content {
  background: white;
  padding: 40px 20px;
  border-radius: 20px;
  max-width: 600px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.modal-logo {
  width: 200px;
  margin-right: 20px;
}

.modal-course-image {
  width: 300px;
  height: 200px;
  border-radius: 15px; /* Округлые края */
  object-fit: cover;
  margin-bottom: 20px;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.modal-body {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.modal-form {
  flex: 1;
}

.modal-form p {
  margin-bottom: 15px;
  font-size: 20px;
  TEXT-ALIGN: left;
}

.form-group {
  margin-bottom: 15px;
  position: relative;
}

.form-group input {
  width: 100%;
  padding: 10px 15px;
  border-radius: 30px;
  border: none; /* Убираем стандартный бордер */
  box-sizing: border-box;
  font-size: 16px;
  background-color: white; /* Добавляем белый фон для инпута */
  position: relative;
  z-index: 1; /* Устанавливаем z-index для правильного наложения псевдоэлемента */
}

.form-group::before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  border-radius: 30px;
  background: linear-gradient(to right, #EE322E, #FEDA3A);
  z-index: 0; /* Устанавливаем z-index ниже, чем у инпута */
  transition: opacity 0.3s ease; /* Плавный переход для скрытия бордера */
}

.form-group input:focus::before {
  opacity: 0; /* Скрываем бордер при фокусе */
}

.subscribe-button {
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  background-image: linear-gradient(to right, #EE322E, #FEDA3A);
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.subscribe-button:hover {
  background-image: linear-gradient(to right, #FEDA3A, #EE322E);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.close-line {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 2px;
  background: #EE322E;
  transform: translate(-50%, -50%) rotate(45deg);
}

.close-line-2 {
  background: #602B84;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.call-to-action-mod {
  margin-top: 20px;
}

.call-to-action-mod p {
  color: black;
  font-size: 18px;
  font-weight: 400; /* DM Sans Regular */
}

.input-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.input-container select.country-code {
  padding: 10px;
  border: none;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: white;
  cursor: pointer;
  height: 42px;
  width: 90px;
  border: 1px solid #ee322e;
}

.input-container input {
  padding: 10px;
  border: none;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  flex: 1;
  height: 20px;
  border: 1px solid #ee322e;
}
.input-container-name input {
  padding: 10px;
  border: none;
  border-radius: 30px;
  flex: 1;
  height: 20px;
  border: 1px solid #ee322e;
}
.input-container button {
  padding: 10px;
  background-color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  border: 1px solid #ee322e;
}

.input-container button span {
  font-size: 20px;
  color: #EE322E;
}
.course-selector {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 15%;
  width: 100%;
  max-width: 400px;
  font-family: 'DM Sans', sans-serif;
}

.course-selector label {
  display: block;
  font-size: 16px;
  margin-bottom: 8px;
}

.course-selector select {
  appearance: none;
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid #EE322E;
  background-color: white;
  font-family: 'DM Sans', sans-serif;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24"><path fill="%23EE322E" d="M12 15l-8-8h16z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 12px 12px;
  transition: border-color 0.3s ease;
}

.course-selector select:focus {
  border-color: #FEDA3A;
  outline: none;
}

.course-selector p {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
  white-space: pre-wrap;
  line-height: 1.5;
  text-align: left;
  font-family: 'DM Sans', sans-serif;
}

/* Адаптивность для планшетов */
@media (max-width: 1024px) {
  
  .hero-section {
    padding: 0 20px;
  }

  .hero-content {
    margin-left: 10px;
    width: 40%;
  }

  .hero-content h1 {
    font-size: 28px;
  }
  .custom-select-wrapper {
    width: 20%;
  }

  .selected-option {
    padding: 11px;
    height: 37px;
  }

  .options {
    max-height: 160px;
  }

  .option {
    padding: 9px;
  }
  .call-to-action p {
    font-size: 18px;
  }

  .input-container {
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 10px;
  }

  .input-container select.country-code {
    height: 62px;
    flex: 1;
    width: 1%;
    color: black;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  .input-container input {
    height: 39px;
    flex: 1;
    width: 85%;
    margin-bottom: 0;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  .input-container button {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    margin-left: 10px;
  }

  .programs-section h2,
  .next-courses-section h2,
  .culture-content h2 {
    font-size: 22px;
  }

  .programs-section {
    padding: 0 10px;
  }
  .culture-content .input-container button{
    width: 56px;
    height: 56px;
  }
  .course-card {
    width: 100%;
  }

  .culture-content {
    width: 44%;
  }

  .course-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .course-name,
  .join-button,
  .course-date {
    flex: none;
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
  }

  .join-button {
    width: auto;
    margin-bottom: -6%;
    margin-left: 50%;
  }

  .course-date {
    text-align: left;
    margin-left: 0;
  }
  .culture-content {
    width: 50%;
    margin-left:30px;
  }

  .next-courses-section {
    padding: 0 20px;
  }
  
  .newsletter-section {
    flex-direction: column;
    align-items: stretch;
    padding: 20px;
    width: 100%;
  }

  .newsletter-content {
    flex: 1;
    padding-right: 0;
    margin-bottom: 20px;
  }

  .newsletter-input-container {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .newsletter-input-container input {
    height: 40px;
  }

  .newsletter-input-container button {
    height: 54px;
    margin-right: 13%; 
  }

  .courses-swiper {
    width: 60%;
  }

  .swiper-pagination-bullet-active {
    background: #FEDA3A;
  }
}

/* Адаптивность для мобильных устройств */
@media (max-width: 768px) {
  .hero-section {
    padding: 0 20px;
    height: 70vh;
  }
  .custom-select-wrapper {
    width: 20%;
  }

  .selected-option {
    padding: 11px;
    height: 40px;
  }

  .options {
    max-height: 150px;
  }

  .option {
    padding: 8px;
  }
  .hero-content {
    margin-left: 70px;
    width: 60%;
    margin-top: 15%;
  }

  .hero-content h1 {
    font-size: 28px;
  }

  .call-to-action p {
    font-size: 18px;
  }

  .input-container {
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 10px;
  }

  .input-container select.country-code {
    height: 62px;
    flex: 1;
    width: 1%;
    color: black;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  .input-container input {
    height: 40px;
    flex: 1;
    width: 85%;
    margin-bottom: 0;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  .input-container button {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    margin-left: 10px;
  }

  .programs-section h2,
  .next-courses-section h2,
  .culture-content h2 {
    font-size: 22px;
  }

  .programs-section {
    padding: 0 10px;
  }

  .course-card {
    width: 100%;
  }

  .culture-content {
    width: 60%;
  }

  .course-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .course-name,
  .join-button,
  .course-date {
    flex: none;
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
  }

  .join-button {
    width: auto;
    margin-bottom: -8%;
    margin-left: 50%;
  }

  .course-date {
    text-align: left;
    margin-left: 0;
  }
  .culture-section {
    height: 70vh;
  }
  .culture-content {
    width: 50%;
    margin-right:30px;
  }
  .culture-content .input-container button{
    width: 56px;
    height: 56px;
  }

  .next-courses-section {
    padding: 0 20px;
    margin-top: 10%;
  }

  .newsletter-section {
    flex-direction: column;
    align-items: stretch;
    padding: 20px;
    width: 100%;
  }

  .newsletter-content {
    flex: 1;
    padding-right: 0;
    margin-bottom: 20px;
  }

  .newsletter-input-container {
    flex: 1;
    display: flex;
    align-items: center;
  }
  
  .newsletter-input-container input {
    height: 40px;
  }

  .newsletter-input-container button {
    height: 54px;
    margin-right: 10%;
  }

  .courses-swiper {
    width: 80%;
  }

  .swiper-pagination-bullet-active {
    background: #FEDA3A;
  }
  .course-selector {
    width: 90%;
    margin-left: 7%;
    font-family: 'DM Sans', sans-serif;
  }
  .cta-button, .info-button {
    width: 100%;
    max-width: 300px;
  }
  .cta-bot-button, .info-bot-button {
    width: 100%;
    max-width: 300px;
  }
  .modal-content {
    width: 80%;
    padding: 30px 15px;
    overflow-y: auto;
    max-height: 70vh;
    overflow-x: hidden
  }

  .modal-logo {
    width: 200px;
  }
}

/* Адаптивность для мобильных устройств */
@media (max-width: 480px) {
  .hero-section {
    height: 60vh;
  }
  .hero-content {
    text-align: left;
    width: 50%;
    height: 40%;
    margin: 0 auto;
    justify-content: center;
    margin-left: 40px;
    margin-top: 15%;
  }
  .custom-select-wrapper {
    width: 20%;
  }

  .selected-option {
    padding: 9px;
    height: 30px;
    font-size: 12px;
  }

  .options {
    max-height: 140px;
  }

  .option {
    padding: 7px;
    font-size: 12px;
  }
  .hero-content h1 {
    font-size: 24px;
  }
  .course-card img {
    height: 300px;
  }  
  .call-to-action p {
    font-size: 16px;
  }
  .course-selector {
    width: 80%;
    margin-left: 10%;
    
    font-family: 'DM Sans', sans-serif;
  }
  .input-container {
    flex-direction: row;
    align-items: center;
    width: 110%;
    margin-top: 10px;
  }
  .culture-content .input-container button {
    width: 44px;
    height: 44px;
  }
  .input-container select.country-code {
    height: 50px;
    flex: 1;
    margin-bottom: 0;
    width: 65px;
  }

  .input-container input {
    height: 30px;
    flex: 1;
    height: 28px;
    width: 100px;
    margin-bottom: 0;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  .input-container select.country-code {
    width: 1%;
    color: black;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
  }

  .input-container button {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    margin-left: 10px;
  }

  .programs-section h2,
  .next-courses-section h2,
  .culture-content h2 {
    font-size: 20px;
  }

  .programs-section {
    padding: 0 10px;
  }

  .course-item {
    flex-direction: column;
    align-items: flex-start;
  }
  .culture-section {
    height: 60vh;
  }
  .course-name,
  .join-button,
  .course-date {
    flex: none;
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
  }

  .join-button {
    width: auto;
    margin-bottom: -8%;
    margin-left: 50%;
  }

  .course-date {
    text-align: left;
    margin-left: 0;
  }

  .course-card {
    width: 100%;
  }

  .culture-content {
    width: 50%;
    margin-right:30px;
    height: 40%;
  }

  .newsletter-section {
    padding: 20px;
    width: 90%;
  }

  .newsletter-input-container input {
    height: 40px;
  }

  .newsletter-input-container button {
    height: 54px;
  }

  .courses-swiper {
    width: 90%;
  }

  .swiper-pagination-bullet-active {
    background: #FEDA3A;
  }

  .swiper-container {
    display: flex;
    justify-content: flex-end;
  }
   .modal-content {
    width: 85%;
    padding: 20px 10px;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden
  }
  .modal-body {
    flex-direction: column;
  }
  .modal-logo {
    width: 200px;
  }
  .cta-button, .info-button {
    width: 100%;
  }
  .cta-bot-button, .info-bot-button {
    width: 100%;
  }

  .swiper-gif {
    width: 30%;
    height: auto;
  }
}

</style>
