<template>
  <div class="widget-container">
    <div class="language-switcher" @click.stop="toggleLanguageMenu">
      <div class="language-icon-wrapper">
        <div class="language-icon-border"></div>
        <div class="language-icon">
          <img :src="currentLanguageIcon" alt="Language" />
        </div>
      </div>
      <ul v-if="isLanguageMenuOpen" class="language-menu">
        <li v-for="lang in languages" :key="lang.code" @click="changeLanguage(lang.code)">
          <img :src="lang.icon" :alt="lang.name" /> {{ lang.name }}
        </li>
      </ul>
    </div>
    <div
      class="widget-button"
      v-for="(icon, index) in icons"
      :key="index"
      :style="{ backgroundColor: icon.color }"
      @click="handleClick(icon.action)"
    >
      <img :src="icon.src" :alt="icon.alt" />
      <span class="icon-label">{{ icon.alt }}</span> <!-- Добавленный элемент для подписи -->
    </div>
  </div>
</template>



<script>
import ruIcon from '@/assets/ru-icon.svg';
import enIcon from '@/assets/en-icon.svg';
import deIcon from '@/assets/de-icon.svg';
import zhIcon from '@/assets/zh-icon.svg';
import esIcon from '@/assets/es-icon.svg';

export default {
  name: "LanguageWidget",
  data() {
    return {
      isLanguageMenuOpen: false,
      languages: [
        { code: 'en', name: 'English', icon: enIcon },
        { code: 'ru', name: 'Русский', icon: ruIcon },
        { code: 'de', name: 'Deutsch', icon: deIcon },
        { code: 'zh', name: '中文', icon: zhIcon },
        { code: 'es', name: 'Español', icon: esIcon }
      ],
      icons: [
        { src: require('@/assets/login-icon.svg'), alt: "Login", color: "#25D366", action: 'login' },
        { src: require('@/assets/enquire-icon.svg'), alt: "Enquire", color: "#d8b9f2", action: 'enquire' },
        { src: require('@/assets/chat-icon.svg'), alt: "Chat", color: "#007bff", action: 'chat' },
        { src: require('@/assets/mail-icon.svg'), alt: "Email", color: "#d8b9f2", action: 'mail' },
        { src: require('@/assets/whatsapp-icon.svg'), alt: "WhatsApp", color: "#25D366", action: 'whatsapp' }
      ]
    };
  },
  computed: {
    currentLanguageIcon() {
      const currentLang = this.languages.find(lang => lang.code === this.$i18n.locale);
      return currentLang ? currentLang.icon : enIcon;
    }
  },
  methods: {
    toggleLanguageMenu() {
      this.isLanguageMenuOpen = !this.isLanguageMenuOpen;
      if (this.isLanguageMenuOpen) {
        document.addEventListener('click', this.closeLanguageMenuOnOutsideClick);
      } else {
        document.removeEventListener('click', this.closeLanguageMenuOnOutsideClick);
      }
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      this.isLanguageMenuOpen = false;
      document.removeEventListener('click', this.closeLanguageMenuOnOutsideClick);
    },
    closeLanguageMenuOnOutsideClick(event) {
      if (!this.$el.contains(event.target)) {
        this.isLanguageMenuOpen = false;
        document.removeEventListener('click', this.closeLanguageMenuOnOutsideClick);
      }
    },
    handleClick(action) {
      if (action === 'chat') {
        this.$emit('open-chat');
      } else if (action === 'enquire') {
        this.$emit('open-enquire');
      } else if (action === 'mail') {
        window.location.href = 'mailto:info@elspanglish.es'; // Update with your email
      } else if (action === 'whatsapp') {
        window.location.href = 'https://wa.me/+34624114256'; 
      }
    }
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeLanguageMenuOnOutsideClick);
  }
};
</script>



<style scoped>
.widget-container {
  position: fixed;
  left: 20px;
  top: 60%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #333;
  border-radius: 20px;
  padding: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.language-switcher {
  margin-bottom: 0px;
}

.language-icon-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.language-icon-border {
  position: absolute;
  background-color: #333;
  width: 70px;
  height: 65px;
  border-radius: 20px 20px 0 0;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

.language-icon {
  background-color: #d8b9f2;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
}

.language-icon img {
  width: 30px;
  height: 30px;
}

.language-icon:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
}

.language-menu {
  position: absolute;
  top: 40px;
  left: 70px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  list-style: none;
  margin: 0;
  padding: 10px 0;
  width: 140px;
  z-index: 1000;
}

.language-menu li {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.language-menu li:hover {
  background-color: #f0f0f0;
}

.language-menu img {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.widget-button {
  margin: 15px 0;
  position: relative;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.widget-button img {
  width: 24px;
  height: 24px;
  display: block;
}

.icon-label {
  position: absolute;
  bottom: -20px; /* Разместите текст под иконкой */
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  color: #fff;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none; /* Исключаем текст из событий мыши */
}

.widget-button:hover {
  transform: scale(1.2);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
}

.widget-button:hover .icon-label {
  opacity: 1; /* Показываем текст при наведении */
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.modal-content {
  background: white;
  padding: 40px 20px;
  border-radius: 20px;
  max-width: 600px;
  width: 90%;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-logo {
  width: 200px;
  margin-right: 20px;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
}


</style>

