import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import CursosPage from '../views/CursosPage.vue';
import UnderConstruction from '../views/UnderConstruction.vue';
import ExamsPage from '../views/ExamsPage.vue';
import BookingPage from '../views/BookingPage.vue';
import ContactPage from '../views/ContactPage.vue';
import NotFound from '../views/NotFound.vue'; // новый компонент 404
const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/cursos',
    name: 'CursosPage',
    component: CursosPage
  },
  {
    path: '/exams',
    name: 'ExamsPage',
    component: ExamsPage // Добавляем маршрут для страницы экзаменов
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
  },
  {
    path: '/booking',
    name: 'BookingPage',
    component: BookingPage
  },
  {
    path: '/contact',
    name: 'ContactPage',
    component: ContactPage
  },
  {
    path: '/under-construction',
    name: 'UnderConstruction',
    component: UnderConstruction
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;

