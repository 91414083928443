<template>
  <div class="booking-page">
    <div class="header-wrapper">
      <SecHeader />
    </div>
    <div class="background-section">
      <img src="@/assets/2_booking.webp" alt="Booking Background" class="background-image" />
    </div>
    <div class="booking-content">
      <h1>{{ $t('bookingTitle') }}</h1>
      <div class="booking-form-container">
        <div class="booking-form">
          <label for="start-date">{{ $t('startDate') }}</label>
          <Datepicker v-model="startDate" :lang="datepickerLang" class="input-field" />

          <label for="course-type">{{ $t('courseType') }}</label>
          <select id="course-type" v-model="courseType" class="input-field">
            <option value="group">{{ $t('examDele') }}</option>
            <option value="individual">{{ $t('examCcse') }}</option>
            <option value="online">{{ $t('examCambridge') }}</option>
            <option value="group">{{ $t('spanishCourses') }}</option>
            <option value="individual">{{ $t('englishCourses') }}</option>
            <option value="online">{{ $t('coursesAbroad') }}</option>
          </select>

          <div class="contact-info">
            <label for="name">{{ $t('name') }}</label>
            <input type="text" id="name" v-model="name" class="input-field" />

            <label for="email">{{ $t('email') }}</label>
            <input type="email" id="email" v-model="email" class="input-field" />

            <label for="phone">{{ $t('phone') }}</label>
            <input type="tel" id="phone" v-model="phone" class="input-field" />
          </div>

          <button @click="bookCourse" class="book-button">{{ $t('bookNow') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SecHeader from './SecHeader.vue';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  components: {
    SecHeader,
    Datepicker,
  },
  data() {
    return {
      startDate: null,
      courseType: 'group',
      intensity: 'standard',
      duration: '1',
      name: '',
      email: '',
      phone: '',
      datepickerLang: {
        // localization settings here
      },
    };
  },
  methods: {
    bookCourse() {
      // Function to handle booking
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

body {
  margin: 0;
  padding: 0;
  font-family: 'DM Sans', sans-serif;
}

.booking-page {
  width: 100%;
}

.header-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.background-section {
  width: 100%;
  height: 60vh; /* Высота 60% от высоты экрана */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}
.booking-content {
  background-color: none; /* Coral */
  color: black;
  padding: 40px;
  border-radius: 10px;
  max-width: 800px;
  margin: 40px auto 0 auto; /* Отступ сверху для размещения под фиксированным хедером */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.booking-content h1 {
  text-align: center;
  font-size: 36px;
  margin-bottom: 20px;
}

.booking-form-container {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}

.booking-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.booking-form label {
  font-size: 16px;
}

.booking-form select,
.booking-form input {
  width: 100%;
  padding: 10px;
  border-radius: 50px;
  border: 2px solid #EE322E;
  font-size: 16px;
  background-color: white;
  box-sizing: border-box;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact-info label {
  font-size: 16px;
}

.contact-info input {
  width: 100%;
  padding: 10px;
  border-radius: 50px;
  border: 2px solid #EE322E;
  font-size: 16px;
  background-color: white;
  box-sizing: border-box;
}

.book-button {
  padding: 15px;
  background-color: #EE322E; /* Morado */
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.book-button:hover {
  background-color: #FEDA3A; /* Darker Morado */
  color: black;
}

/* Адаптация для планшетов */
@media (max-width: 768px) {
  .background-section {
    height: 60hv;
  }
  .booking-content {
    padding: 40px;
    max-width: 600px;
    margin: -120px auto 55px auto;
  }
  .background-image {
    height: 60%;
  }
  .booking-content h1 {
    font-size: 28px;
  }

  .booking-form {
    gap: 15px;
  }
}

/* Адаптация для мобильных устройств */
@media (max-width: 480px) {
  .background-section {
    height: 60hv;
  }
  .booking-content {
    padding: 40px;
    max-width: 100%;
  }
  .background-image {
    height: 55%;
  }
  .booking-content h1 {
    font-size: 24px;
  }

  .booking-form {
    gap: 10px;
  }

  .book-button {
    font-size: 14px;
    padding: 14px;
  }
}
</style>
