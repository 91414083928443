<template>
  <div>
    <ExamsContent />
  </div>
</template>

<script>
import ExamsContent from '../components/ExamsContent.vue';

export default {
  name: 'ExamsPage',
  components: {
    ExamsContent
  }
};
</script>

<style scoped>
/* ваш стиль */
</style>
