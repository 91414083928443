import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Импортируйте маршрутизатор
import i18n from './i18n';
import 'swiper/swiper-bundle.css';
import store from './store'; 

const app = createApp(App);



app.use(i18n);
app.use(store) 
app.use(router); // Используйте маршрутизатор
app.mount('#app');
