<template>
  <div>
    <CursosContent />
  </div>
</template>

<script>
import CursosContent from '../components/CursosContent.vue';

export default {
  name: 'CursosPage',
  components: {
    CursosContent
  }
};
</script>

<style scoped>
/* ваш стиль */
</style>
