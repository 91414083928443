<template>
  <header :class="{ scrolled: isScrolled }">
    <div class="header-container" @click.self="closeMenus">
      <nav class="desktop-nav">
        <ul class="nav-list left">
          <li>
            <router-link to="/home" active-class="active">{{ $t('Spanglish') }}</router-link>
          </li>
          <li>
            <router-link to="/cursos" active-class="active">{{ $t('Courses') }}</router-link>
          </li>
          <li>
            <router-link to="/exams" active-class="active">{{ $t('Exams') }}</router-link>
          </li>
        </ul>
      </nav>
      <img :src="isScrolled ? require('@/assets/Logo-color.png') : require('@/assets/Logo-color.png')" class="logo desktop-logo" @click="scrollToTop" alt="Logo">
      <img src="@/assets/Mob-logo.svg" class="logo mobile-logo" @click="scrollToTop" alt="Mobile Logo">
      <button class="menu-toggle" @click="toggleMenu">
        <div class="menu-bar"></div>
        <div class="menu-bar"></div>
        <div class="menu-bar"></div>
      </button>
      <nav class="desktop-nav">
        <ul class="nav-list right">
          <li>
            <router-link to="/booking" active-class="active">{{ $t('Booking') }}</router-link>
          </li>
          <li>
            <router-link to="/under-construction" active-class="active">{{ $t('Blog') }}</router-link>
          </li>
          <li>
            <router-link to="/contact" active-class="active">{{ $t('Contact') }}</router-link>
          </li>
        </ul>
      </nav>
    </div>
    <nav class="mobile-nav" :class="{ open: isMenuOpen }">
      <ul class="nav-list">
        <li><router-link to="/home" @click="toggleMenu">{{ $t('Spanglish') }}</router-link></li>
        <li><router-link to="/cursos" @click="toggleMenu">{{ $t('Courses') }}</router-link></li>
        <li><router-link to="/exams" @click="toggleMenu">{{ $t('Exams') }}</router-link></li>
        <li><router-link to="/booking" @click="toggleMenu">{{ $t('Booking') }}</router-link></li>
         <li><router-link to="/under-construction" @click="toggleMenu">{{ $t('Blog') }}</router-link></li>
        <li><router-link to="/contact" @click="toggleMenu">{{ $t('Contact') }}</router-link></li>
        <li class="language-selector">
          <div @click="toggleLanguageMenu">
            <img :src="currentLanguageIcon" :alt="currentLanguageName" class="language-icon">
            <span>{{ currentLanguageName }}</span>
          </div>
          <ul v-if="isLanguageMenuOpen" class="language-menu">
            <li v-for="lang in languages" :key="lang.code" @click="changeLanguage(lang.code)">
              <img :src="lang.icon" :alt="lang.name" class="language-icon">
              <span>{{ lang.name }}</span>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      isMenuOpen: false,
      isLanguageMenuOpen: false,
      activeSection: 'home',
      isScrolled: false,
      languages: [
        { code: 'en', name: 'ENG', icon: require('@/assets/en-icon.svg') },
        { code: 'ru', name: 'RUS', icon: require('@/assets/ru-icon.svg') },
        { code: 'de', name: 'DEU', icon: require('@/assets/de-icon.svg') },
        { code: 'zh', name: 'ZHH', icon: require('@/assets/zh-icon.svg') },
        { code: 'es', name: 'ESP', icon: require('@/assets/es-icon.svg') }
      ]
    };
  },
  computed: {
    currentLanguageIcon() {
      const currentLang = this.languages.find(lang => lang.code === this.$i18n.locale);
      return currentLang ? currentLang.icon : require('@/assets/en-icon.svg');
    },
    currentLanguageName() {
      const currentLang = this.languages.find(lang => lang.code === this.$i18n.locale);
      return currentLang ? currentLang.name : 'ENG';
    }
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
      const menuToggle = document.querySelector('.menu-toggle');
      if (this.isMenuOpen) {
        menuToggle.classList.add('open');
        document.addEventListener('click', this.closeMenuOnOutsideClick);
      } else {
        menuToggle.classList.remove('open');
        document.removeEventListener('click', this.closeMenuOnOutsideClick);
      }
    },
    toggleLanguageMenu() {
      this.isLanguageMenuOpen = !this.isLanguageMenuOpen;
      if (this.isLanguageMenuOpen) {
        document.addEventListener('click', this.closeLanguageMenuOnOutsideClick);
      } else {
        document.removeEventListener('click', this.closeLanguageMenuOnOutsideClick);
      }
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      this.isLanguageMenuOpen = false;
      document.removeEventListener('click', this.closeLanguageMenuOnOutsideClick);
    },
    closeMenus() {
      if (this.isMenuOpen) {
        this.isMenuOpen = false;
        document.removeEventListener('click', this.closeMenuOnOutsideClick);
      }
      if (this.isLanguageMenuOpen) {
        this.isLanguageMenuOpen = false;
        document.removeEventListener('click', this.closeLanguageMenuOnOutsideClick);
      }
    },
    closeMenuOnOutsideClick(event) {
      if (!this.$el.contains(event.target)) {
        this.isMenuOpen = false;
        document.removeEventListener('click', this.closeMenuOnOutsideClick);
      }
    },
    closeLanguageMenuOnOutsideClick(event) {
      if (!this.$el.contains(event.target)) {
        this.isLanguageMenuOpen = false;
        document.removeEventListener('click', this.closeLanguageMenuOnOutsideClick);
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    updateActiveSection() {
      const sections = document.querySelectorAll('section');
      sections.forEach(section => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= window.innerHeight / 2 && rect.bottom >= window.innerHeight / 2) {
          this.activeSection = section.id;
        }
      });
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 100;
    }
  },
  watch:  {
    $route(to) {
      const path = to.path;
      if (path.includes('home')) {
        this.activeSection = 'home';
      } else if (path.includes('cursos')) {
        this.activeSection = 'courses';
      } else if (path.includes('exams')) {
        this.activeSection = 'exams';
      } else if (path.includes('booking')) {
        this.activeSection = 'booking';
      } else if (path.includes('under-construction')) {
        this.activeSection = 'blog';
      } else if (path.includes('contact')) {
        this.activeSection = 'contact';
      }
    },
  },
  mounted() {
    this.updateActiveSection();
    window.addEventListener('scroll', this.updateActiveSection);
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.updateActiveSection);
    window.removeEventListener('scroll', this.handleScroll);
  }
};
</script>

<style scoped>
body {
  overflow-x: hidden; /* Убираем горизонтальную прокрутку */
}

header {
  padding: 0;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: white; /* Прозрачный фон */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

header.scrolled {
  background-color: tra; /* Белый фон при прокрутке */
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  padding: 0px 20px;
  position: relative;
}

.logo {
  flex-shrink: 0;
  cursor: pointer;
  height: 120px;
  transition: all 0.3s ease;
}

header.scrolled .desktop-logo {
  height: 120px; /* Увеличиваем размер логотипа при прокрутке */
}

.desktop-logo {
  display: block;
}

.desktop-logo.scrolled {
  content: url('../assets/Logo-color.png'); /* Новый логотип при прокрутке */
}

.mobile-logo {
  display: none;
}

.nav-list {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
}

.nav-list.left {
  margin-right: auto;
}

.nav-list.right {
  margin-left: auto;
}

.nav-list li {
  margin: 0 15px;
}

.nav-list a {
  text-decoration: none;
  color: black;
  padding: 10px 20px;
  border: 2px solid black;
  border-radius: 25px;
  transition: all 0.3s ease;
}

header.scrolled .nav-list a {
  color: black; /* Черный цвет текста при прокрутке */
  border-color: black; /* Черный цвет границ при прокрутке */
}

.nav-list a.active,
.nav-list a:hover {
  background-color: #FEDA3A;
  color: #333;
  transform: scale(1.05);
}


.menu-toggle {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 24px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  transition: all 0.3s ease;
  position: relative; /* Для абсолютного позиционирования псевдоэлементов */
}

.menu-bar {
  width: 24px;
  height: 3px;
  background-color: black;
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.menu-toggle.open .menu-bar:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
  background-color: #EE322E; /* Верхняя полоска красного цвета */
}

.menu-toggle.open .menu-bar:nth-child(2) {
  opacity: 0;
}

.menu-toggle.open .menu-bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
  background-color: #FEDA3A; /* Нижняя полоска желтого цвета */
}

header.scrolled .menu-bar {
  background-color: black; /* Черные полоски меню при прокрутке */
}

header.scrolled .menu-toggle.open .menu-bar:nth-child(1) {
  background-color:#FEDA3A;
}

header.scrolled .menu-toggle.open .menu-bar:nth-child(3) {
  background-color: #EE322E;
}

.mobile-nav {
  display: none;
  flex-direction: column;
  background-color: rgba(96,43, 132, 0.8);
  position: absolute;
  top: 67%;
  right:7%;
  width: 50%;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  align-items: center; /* Центрируем элементы по горизонтали */
  border-radius: 40% 5% 40% 40%;
  margin-left:15%;
}

.mobile-nav.open {
  display: flex;
}

.mobile-nav .nav-list {
  flex-direction: column;
  align-items: center; /* Центрируем элементы по горизонтали */
}

.mobile-nav .nav-list li {
  margin: 15px 0;
}

.mobile-nav .nav-list a {
  width: 150px; /* Фиксированная ширина для одинакового размера пунктов меню */
  text-align: center; /* Центрируем текст */
  color: #fff;
  border: 2px solid #fff;
}
.language-selector {
  margin-top: 20px;
  position: relative;
}

.language-selector div {
  display: flex;
  align-items: center;
  cursor: pointer;
  color:white;
}

.language-selector .language-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.language-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  list-style: none;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.language-menu li {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 10px;
}

.language-menu li:hover {
  background-color: #f0f0f0;
}

.language-menu .language-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

/* Стили для устройств с шириной до 1026px */
@media (max-width: 1026px) {
  header.scrolled .nav-list a {
    color: white; /* Белый цвет текста при прокрутке */
    border-color: white; /* Белый цвет границ при прокрутке */
  }
  header.scrolled {
    background-color: rgba(255, 255, 255); /* Прозрачный фон при прокрутке */
  }
  .desktop-nav {
    display: none;
  }

  .desktop-logo {
    display: none;
  }

  .mobile-logo {
    display: block;
    height: 80px; /* Увеличенная высота мобильного логотипа */
  }

  .menu-toggle {
    display: flex;
    height: 30px; /* Увеличенная высота кнопки меню */
  }

  .menu-bar {
    width: 30px; /* Увеличенная ширина меню */
    height: 4px; /* Увеличенная высота полосок меню */
  }

  .header-container {
    justify-content: space-between;
  }
}

/* Стили для устройств с шириной до 768px */
@media (max-width: 768px) {
  header.scrolled {
    background-color: rgba(255, 255, 255); /* Прозрачный фон при прокрутке */
  }
  .desktop-nav {
    display: none;
  }

  .desktop-logo {
    display: none;
  }

  .mobile-logo {
    display: block;
    height: 80px; /* Увеличенная высота мобильного логотипа */
  }

  .menu-toggle {
    display: flex;
    height: 30px; /* Увеличенная высота кнопки меню */
  }

  .menu-bar {
    width: 30px; /* Увеличенная ширина меню */
    height: 4px; /* Увеличенная высота полосок меню */
  }

  .header-container {
    justify-content: space-between;
  }

  .mobile-nav {
    justify-content: center; /* Центрируем элементы по вертикали */
  }
  .mobile-nav .nav-list {
    flex-direction: column;
    align-items: center; /* Центрируем элементы по горизонтали */
    margin-left: 12%;
  }
}

@media (max-width: 480px) {
  header.scrolled .nav-list a {
    color: white; /* Белый цвет текста при прокрутке */
    border-color: white; /* Белый цвет границ при прокрутке */
  }
  header.scrolled {
    background-color: rgba(255, 255, 255); /* Прозрачный фон при прокрутке */
  }
  .desktop-nav {
    display: none;
  }

  .desktop-logo {
    display: none;
  }

  .mobile-logo {
    display: block;
    height: 80px; /* Увеличенная высота мобильного логотипа */
  }

  .menu-toggle {
    display: flex;
    height: 30px; /* Увеличенная высота кнопки меню */
  }

  .menu-bar {
    width: 30px; /* Увеличенная ширина меню */
    height: 4px; /* Увеличенная высота полосок меню */
  }

  .header-container {
    justify-content: space-between;
  }

  .mobile-nav {
    justify-content: center; /* Центрируем элементы по вертикали */
  }
  
  .mobile-nav .nav-list {
    flex-direction: column;
    align-items: center; /* Центрируем элементы по горизонтали */

  }
}


</style>
