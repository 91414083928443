<template>
  <div class="chat-widget-container" v-if="isChatVisible">
    <div class="chat-header">
      <h3>{{ $t('onlineChat') }}</h3>
      <button class="buttom-refresh-header" @click="refreshChat">{{ $t('refresh') }}</button>
      <button class="buttom-close-header" @click="closeChat">X</button>
    </div>
    <div class="chat-messages" ref="messagesContainer">
      <div v-for="(message, index) in messages" :key="index" :class="['chat-message', message.sender]">
        <p>{{ message.text }}</p>
        <span class="timestamp">{{ message.timestamp }}</span>
        <div v-if="message.options" class="chat-options">
          <button v-for="option in message.options" :key="option.text" @click="handleOptionClick(option.action)">
            {{ option.text }}
          </button>
        </div>
      </div>
    </div>
    <div class="chat-input" v-if="isChatVisible && !currentOptions">
      <input v-model="userMessage" @keyup.enter="sendMessage" :placeholder="$t('typeMessage')" />
      <button class="buttom-send-header" @click="sendMessage">{{ $t('send') }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatWidget",
  props: {
    isChatOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isChatVisible: this.isChatOpen,
      userMessage: '',
      messages: [],
      currentOptions: null,
      eventSource: null,
      userName: null,
      userPhone: null,
      chatStep: 1,
      sessionId: this.generateSessionId(),
      lastMessageTime: null,
      timeoutChecker: null,
    };
  },
  created() {
    this.loadMessages();
    this.startAutoRefresh();
    this.startTimeoutChecker();
  },
  watch: {
    isChatOpen(newVal) {
      this.isChatVisible = newVal;
      if (newVal) {
        this.openChat();
        this.startListeningForMessages();
      } else {
        this.closeChat();
      }
    },
  },
  methods: {
    openChat() {
      this.isChatVisible = true;
      if (this.messages.length === 0) {
        this.addMessage(this.$t('welcomeMessage'), 'bot');
      }
    },
    closeChat() {
      this.$emit('close-chat');
      this.isChatVisible = false;
      this.userMessage = '';
      this.messages = [];
      this.currentOptions = null;
      this.userName = null;
      this.userPhone = null;
      this.chatStep = 1;
      this.sessionId = this.generateSessionId();
      if (this.eventSource) {
        this.eventSource.close();
        this.eventSource = null;
      }
      this.clearTimeoutChecker();
    },
    refreshChat() {
      if (this.eventSource) {
        this.eventSource.close();
      }
      this.startListeningForMessages();
    },
    addMessage(text, sender, options = null) {
      const timestamp = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      this.messages.push({ text, sender, options, timestamp });
      this.currentOptions = options;
      this.lastMessageTime = Date.now();
      this.$nextTick(() => {
        this.scrollToBottom();
        this.saveMessages();
      });
    },
    handleOptionClick(action) {
      if (action === 'continueChat') {
        this.addMessage(this.$t('managerWillContact'), 'bot');
        this.notifyManager();
      } else if (action === 'closeChat') {
        this.closeChat();
      }
      this.currentOptions = null;
      this.messages = this.messages.map(message => ({ ...message, options: null }));
    },
    async sendMessage() {
      if (this.userMessage.trim()) {
        const message = this.userMessage.trim();
        this.addMessage(message, 'user');
        this.userMessage = '';

        if (this.chatStep === 1) {
          this.userName = message;
          this.addMessage(this.$t('thankYouName', { name: this.userName }), 'bot');
          this.chatStep = 2;
        } else if (this.chatStep === 2) {
          this.userPhone = message;
          this.addMessage(this.$t('thankYouPhone', { phone: this.userPhone }), 'bot', [
            { text: this.$t('yesContinue'), action: 'continueChat' },
            { text: this.$t('noContinue'), action: 'closeChat' }
          ]);
          this.chatStep = 3;
        } else {
          await fetch('/api/webhook', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              message: { text: message, name: this.userName, phone: this.userPhone },
              sessionId: this.sessionId,
            }),
          });
        }
      }
    },
    async notifyManager() {
      await fetch('https://elspanglish.es/api/webhook', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          message: {
            text: this.$t('yesContinue'),
            name: this.userName,
            phone: this.userPhone,
          },
          sessionId: this.sessionId,
        }),
      });
    },
    startListeningForMessages() {
      if (this.eventSource) {
        this.eventSource.close();
      }
      this.eventSource = new EventSource(`/api/events?sessionId=${this.sessionId}`);
      this.eventSource.onmessage = (event) => {
        console.log('Message received from EventSource:', event.data);
        const message = JSON.parse(event.data);
        if (message.text === 'stopchat') {
          this.closeChat();
        } else {
          this.addMessage(message.text, 'manager');
        }
      };
      this.eventSource.onerror = (error) => {
        console.error('EventSource error:', error);
      };
    },

    scrollToBottom() {
      const container = this.$refs.messagesContainer;
      container.scrollTop = container.scrollHeight;
    },
    generateSessionId() {
      return 'session-' + Math.random().toString(36).substr(2, 9);
    },
    saveMessages() {
      localStorage.setItem('chatMessages', JSON.stringify(this.messages));
    },
    loadMessages() {
      const savedMessages = localStorage.getItem('chatMessages');
      if (savedMessages) {
        this.messages = JSON.parse(savedMessages);
      }
    },
    startAutoRefresh() {
      setInterval(() => {
        this.refreshChat();
      }, 10000);
    },
    startTimeoutChecker() {
      this.timeoutChecker = setInterval(() => {
        if (this.lastMessageTime && (Date.now() - this.lastMessageTime > 120000)) {
          this.clearChat();
        }
      }, 10000);
    },
    clearTimeoutChecker() {
      if (this.timeoutChecker) {
        clearInterval(this.timeoutChecker);
        this.timeoutChecker = null;
      }
    },
    clearChat() {
      this.messages = [];
      this.saveMessages();
      this.refreshChat();
    },
  },
};
</script>



<style scoped>
/* Оставляем стили без изменений */
.chat-widget-container {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

@media (min-width: 600px) {
  .chat-widget-container {
    bottom: 20px;
    right: 20px;
    width: 300px;
    height: 400px;
    border-radius: 10px;
  }
}

.chat-header {
  background: #FEDA3A;
  color: black;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.buttom-close-header {
  background-color: white;
  border: none;
  border-radius: 75%;
  cursor: pointer;
  width: 40px;
  height: 40px;
}
.buttom-refresh-header {
  background-color: white;
  border: none;
  border-radius: 10%;
  cursor: pointer;
  width: 75px;
  height: 40px;
}
.buttom-send-header {
  background-color: #FEDA3A;
  border: none;
  border-radius: 10%;
  cursor: pointer;
  width: 85px;
  height: 40px;
}
.chat-messages {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.chat-message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 20px;
  max-width: 80%;
  word-wrap: break-word;
  display: inline-block;
  position: relative;
  min-width: 15%;
}

.chat-message p {
  margin: 0;
  padding-bottom: 20px; /* Добавляем отступ для времени */
}

.chat-message .timestamp {
  font-size: 0.8em;
  color: #888;
  position: absolute;
  bottom: 5px;
}

.chat-message.bot .timestamp {
  left: 10px;
}

.chat-message.user .timestamp {
  right: 10px;
}

.chat-message.bot {
  background-color: #f1f1f1;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 0;
  margin-right: auto;
}

.chat-message.user {
  background-color: #FEDA3A;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 0;
  margin-left: auto;
}

.chat-message.manager {
  background-color: #d8b9f2;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 0;
  padding: 5px 10px;
  margin-bottom: 10px;
  margin-right: auto;
}

.chat-options {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.chat-options button {
    background-color: #FEDA3A;
    border: none;
    border-radius: 28%;
    cursor: pointer;
    width: 133px;
    height: 80px;
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    align-content: center;
}

.chat-input {
  display: flex;
  padding: 10px;
  border-top: 1px solid #ccc;
  background: #f9f9f9;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

chat-input button {
  padding: 10px;
  background: #6a0dad;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.chat-input button:hover {
  background: #FEDA3A;
}
</style>
