<template>
  <div class="exam-page">
    <div class="header-wrapper">
      <SecHeader />
    </div>
    <div class="background-section">
      <img src="@/assets/1_exams.webp" alt="exams Background" class="background-image" />
    </div>
    <div class="content-container">
      <div class="exam-container" v-for="exam in exams" :key="exam.title">
        <div class="exam-card">
          <img :src="exam.image" :alt="exam.title" />
          <div class="exam-info">
            <h2>{{ exam.title }}</h2>
            <p>{{ exam.description }}</p>
            <div class="exam-buttons">
             <button class="cta-button" @click="goToBooking">{{ $t('catalogueButton') }}</button>
              <button class="info-button" @click.stop="openExamModal(exam)">{{ $t('moreInfoButton') }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="background-section-2">
      <img src="@/assets/2_exams.webp" alt="2 exam Background" class="background-image-2" />
      <div class="hero-section-2">
        <button class="cta-bot-button" @click="showModal = true">{{ $t('catalogueButton') }}</button>
        <button class="info-bot-button" @click="showModal = true">{{ $t('moreInfoButton') }}</button>
      </div>
    </div>
    <!-- Modal for Exam Information -->
    <div v-if="showExamModal" class="modal-overlay" @click="closeExamModal">
      <div class="modal-content" @click.stop>
        <div class="modal-header">
          <h2>{{ currentExam.title }}</h2>
          <div class="close-button" @click="closeExamModal">
            <div class="close-line close-line-1"></div>
            <div class="close-line close-line-2"></div>
          </div>
        </div>
        <div class="modal-body">
          <img :src="currentExam.image" :alt="currentExam.title" class="modal-logo" />
          <div v-if="currentExam.title === $t('examDele')">
            <div class="exam-table">
              <div class="exam-row">
                <div class="exam-cell exam-header">{{ $t('ExamDates') }}</div>
                <div class="exam-cell exam-header">{{ $t('Levels') }}</div>
                <div class="exam-cell exam-header">{{ $t('RegistrationDeadlines') }}</div>
              </div>
              <div class="exam-row" v-for="row in deleExamDates" :key="row.date">
                <div class="exam-cell">{{ row.date }}</div>
                <div class="exam-cell">{{ row.levels }}</div>
                <div class="exam-cell">{{ row.deadline }}</div>
              </div>
            </div>
          </div>
          <p v-else>{{ currentExam.description }}</p>
          <a :href="whatsappLink" target="_blank" class="cta-button">{{ $t('Bookacourseonline') }}</a>
        </div>
      </div>
    </div>
    <!-- Modal for Subscription -->
    <div v-if="showModal" class="modal-overlay" @click="showModal = false">
      <div class="modal-content" @click.stop>
        <div class="modal-header">
          <h2>{{ $t('subscribeNewsletters') }}</h2>
          <div class="close-button" @click="showModal = false">
            <div class="close-line close-line-1"></div>
            <div class="close-line close-line-2"></div>
          </div>
        </div>
        <div class="modal-body">
          <img src="@/assets/Spanglish_Logo-Vertical-Color_RGB.png" alt="Spanglish Logo" class="modal-logo" />
          <div class="modal-form">
            <p>{{ $t('latestUpdates') }}</p>
            <form @submit.prevent="submitForm">
              <div class="form-group">
                <input type="email" v-model="email" placeholder="Your Email Address" required />
              </div>
              <button type="submit" class="subscribe-button">{{ $t('Subscribe') }}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import SecHeader from './SecHeader.vue';
import axios from 'axios';

export default {
  components: {
    SecHeader
  },
  data() {
    return {
      exams: [],
      showModal: false,
      showExamModal: false,
      currentExam: {}, // Stores the current exam data for the modal
      email: '',
      whatsappLink: 'https://wa.me/message/3PV3P3DHDQ3CC1',
      deleExamDates: [
        { date: 'February 16', levels: 'Adults: A2', deadline: 'Until January 10, 2024' },
        { date: 'April 12', levels: 'Adults: A1, A2, B1, B2, C1', deadline: 'Until February 21, 2024' },
        { date: 'May 17', levels: 'Young learners: A1, A2/B1', deadline: 'Until April 3, 2024' },
        { date: 'May 18', levels: 'Adults: A1, A2, B1, B2, C1, C2', deadline: 'Until April 3, 2024' },
        { date: 'July 12', levels: 'Adults: A2, B1, B2, C1', deadline: 'Until May 15, 2024' },
        { date: 'September 13', levels: 'Adults: A2', deadline: 'Until July 17, 2024' },
        { date: 'October 18', levels: 'Adults: A2, B1, B2', deadline: 'Until September 4, 2024' },
        { date: 'November 22', levels: 'Young learners: A1, A2/B1', deadline: 'Until October 9, 2024' },
        { date: 'November 23', levels: 'Adults: A1, A2, B1, B2, C1, C2', deadline: 'Until October 9, 2024' }
      ]// For managing the visibility of the phone input
    };
  },
  created() {
    this.setExams(); // Initialize exam data
  },
  watch: {
    '$i18n.locale': 'setExams' // Call setExams when the language changes
  },
  methods: {
    setExams() {
      this.exams = [
        {
          title: this.$t('examDele'),
          description: this.$t('examDeleDesc'),
          image: require('@/assets/exam-dele.png'),
        },
        {
          title: this.$t('examCcse'),
          description: this.$t('examCcseDesc'),
          image: require('@/assets/exam-ccse.png'),
        },
        {
          title: this.$t('examCambridge'),
          description: this.$t('examCambridgeDesc'),
          image: require('@/assets/exam-cambridge.png'),
        }
      ];
    },
    goToBooking() {
      this.$router.push('/booking');
    },
    openExamModal(exam) {
      this.currentExam = exam;
      this.showExamModal = true;
    },
    closeExamModal() {
      this.showExamModal = false;
    },
    submitForm() {
      const language = this.$i18n.locale;

      axios.post('/api/coursesubscribe', { email: this.email, language })
        .then(response => {
          console.log(response.data);
          alert('Subscription successful!');
          this.showModal = false;
          this.email = '';
        })
        .catch(error => {
          console.error(error);
          alert('Subscription failed.');
        });
    },
    bookExamOnline() {
      alert('Забронировать экзамен онлайн.');
      // Add logic for booking an exam here
    }
  }
};
</script>




<style scoped>
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

body {
  margin: 0;
  padding: 0;
  font-family: 'DM Sans', sans-serif;
}

.cursos-page {
  width: 100%;
}

.header-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.background-section {
  width: 100%;
  height: 60vh; /* Высота 60% от высоты экрана */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 120px;
}

.background-section-2 {
  width: 100%;
  height: 450px; /* Можно настроить высоту под ваши нужды */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.background-section-2::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Полупрозрачный черный слой */
}
.hero-section-2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 550px;
} 

  
.background-image-2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-container {
   margin-top: 40px;
   margin-bottom: 40px; /* Отступ сверху для размещения под фиксированным хедером */
}

.exam-table {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  gap: 10px;
  text-align: left;
  border-collapse: collapse;
  padding: 25px;
}

.exam-header {
  background-color: #EE322E;
  color: white;
  padding: 10px;
  font-weight: bold;
}

.exam-row {
  display: contents; /* Позволяет правильно использовать grid с таблицей */
}

.exam-cell {
  padding: 10px;
  border: 1px solid #ddd;
}

.exam-row .exam-cell:not(.exam-header):nth-child(odd) {
  background-color: #f9f9f9;
}

.exam-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.exam-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 800px;
  width: 100%;
  transition: transform 0.3s, box-shadow 0.3s;
}

.exam-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.exam-card img {
  width: 114px;
  height: 150px;
  border-radius: 10px;
  margin-right: 20px;
  object-fit: cover;
}

.exam-info {
  flex: 1;
}

.exam-info h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: black;
  font-weight: bold;
}

.exam-info p {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
}

.exam-details {
  font-size: 14px;
  color: #555;
  margin-bottom: 10px;
}

.exam-details span {
  margin-right: 15px;
}

.exam-buttons {
  display: flex;
  gap: 10px;
}

.cta-button {
  padding: 10px 20px;
  border: 2px solid #EE322E;
  border-radius: 25px;
  background: none;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #EE322E;
}

.cta-button:hover {
  background:  #EE322E;
  color: white;
}

.info-button {
  padding: 10px 20px;
  border: 2px solid #EE322E;
  border-radius: 25px;
  background: #EE322E;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;
}

.info-button:hover {
  background: none;
  border-color:  #EE322E;
  color: #EE322E;
}
.cta-bot-button {
  padding: 10px 20px;
  border: 2px solid #EE322E;
  border-radius: 25px;
  background: #EE322E;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;
  white-space: nowrap;
}

.cta-bot-button:hover {
  background: none;
  color: white;
  border: 2px solid #EE322E;
}

.info-bot-button {
  padding: 10px 20px;
  border: 2px solid #FEDA3A;
  border-radius: 25px;
  background:none;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #FEDA3A;
  white-space: nowrap;
}

.info-bot-button:hover {
  background: #FEDA3A;
  border-color: #333;
  color:#333;
}
/* Стили для модального окна */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.modal-content {
  background: white;
  padding: 40px 20px;
  border-radius: 20px;
  max-width: 600px;
  width: 90%;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.modal-logo {
  width: 200px;
  margin-right: 20px;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.modal-body {
  display: flex;
  align-items: center;
}

.modal-form {
  flex: 1;
}

.modal-form p {
  margin-bottom: 15px;
  font-size: 20px;
  TEXT-ALIGN: left;
}

.form-group {
  margin-bottom: 15px;
  position: relative;
}

.form-group input {
  width: 100%;
  padding: 10px 15px;
  border-radius: 30px;
  border: none; /* Убираем стандартный бордер */
  box-sizing: border-box;
  font-size: 16px;
  background-color: white; /* Добавляем белый фон для инпута */
  position: relative;
  z-index: 1; /* Устанавливаем z-index для правильного наложения псевдоэлемента */
}

.form-group::before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  border-radius: 30px;
  background: linear-gradient(to right, #EE322E, #FEDA3A);
  z-index: 0; /* Устанавливаем z-index ниже, чем у инпута */
  transition: opacity 0.3s ease; /* Плавный переход для скрытия бордера */
}

.form-group input:focus::before {
  opacity: 0; /* Скрываем бордер при фокусе */
}

.subscribe-button {
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  background-image: linear-gradient(to right, #EE322E, #FEDA3A);
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.subscribe-button:hover {
  background-image: linear-gradient(to right, #FEDA3A, #EE322E);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.close-line {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 2px;
  background: #EE322E;
  transform: translate(-50%, -50%) rotate(45deg);
}

.close-line-2 {
  background: #602B84;
  transform: translate(-50%, -50%) rotate(-45deg);
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  overflow-y: auto; /* Добавляем прокрутку для overlay */
}
.modal-content {
  background: white;
  padding: 40px 20px;
  border-radius: 20px;
  max-width: 600px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.modal-logo {
  width: 200px;
  margin-right: 20px;
}

.modal-course-image {
  width: 300px;
  height: 200px;
  border-radius: 15px; /* Округлые края */
  object-fit: cover;
  margin-bottom: 20px;
}
.call-to-action {
  margin-top: 20px;
}

.call-to-action p {
  color: black;
  font-size: 18px;
  font-weight: 400; /* DM Sans Regular */
}

.input-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.input-container select.country-code {
  padding: 10px;
  border: none;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: white;
  cursor: pointer;
  height: 42px;
  width: 90px;
  border: 1px solid #ee322e;
}

.input-container input {
  padding: 10px;
  border: none;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  flex: 1;
  height: 20px;
  border: 1px solid #ee322e;
}
.input-container-name input {
  padding: 10px;
  border: none;
  border-radius: 30px;
  flex: 1;
  height: 20px;
  border: 1px solid #ee322e;
}
.input-container button {
  padding: 10px;
  background-color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  border: 1px solid #ee322e;
}

.input-container button span {
  font-size: 20px;
  color: #EE322E;
}

@media (max-width: 1200px) {
  .hero-section-2 {
    gap: 250px;
  }
}
/* Адаптивность для планшетов */
@media (max-width: 768px) {
  .background-section { 
    height: 60hv; /* Можно настроить высоту под ваши нужды */
  }
  .background-section-2 { 
    height: 400px; /* Можно настроить высоту под ваши нужды */
  }
  .exam-card {
    flex-direction: column;
    text-align: center;
    width: 90%;
  }

  .exam-card img {
    width: 40%;
    max-width: 300px;
    height: auto;
    margin-bottom: 10px;
  }

  .exam-info {
    margin-top: 10px;
  }
  .modal-content {
    width: 80%;
    padding: 30px 15px;
    overflow-y: auto;
    max-height: 70vh;
    overflow-x: hidden
  }

  .modal-logo {
    width: 200px;
  }
  .exam-buttons {
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
  }

  .cta-button, .info-button {
    width: 100%;
    max-width: 300px;
  }
  .cta-bot-button, .info-bot-button {
    width: 100%;
    max-width: 300px;
  }
  .hero-section-2 {
    gap: 30px;
  }
   .modal-content {
    width: 80%;
    padding: 30px 15px;
  }

  .modal-logo {
    width: 200px;
  }

  h2 {
    font-size: 20px;
  }

  .modal-form p {
    font-size: 14px;
    TEXT-ALIGN: left;
  }

  .form-group input {
    padding: 10px 20px;
    font-size: 14px;
  }

  .subscribe-button {
    padding: 10px 50px;
    font-size: 14px;
  }

  .close-button {
    font-size: 14px;
  }
}

/* Адаптивность для мобильных устройств */
@media (max-width: 480px) {
  .background-section { 
    height: 60hv; /* Можно настроить высоту под ваши нужды */
  }
  .background-section-2 { 
    height: 300px; /* Можно настроить высоту под ваши нужды */
  }
  .content-container h1 {
    font-size: 28px;
  }

  .dropdown-container select {
    width: 100%;
    padding: 8px;
    font-size: 14px;
  }
  .modal-content {
    width: 85%;
    padding: 20px 10px;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden
  }
  .modal-body {
    flex-direction: column;
  }
  .modal-logo {
    width: 200px;
  }
  .exam-card {
    padding: 10px;
    width: 85%;

  }
  .exam-card img {
    width: 30%;
  }

  .exam-info h2 {
    font-size: 20px;
  }

  .exam-info p {
    font-size: 14px;
  }

  .exam-buttons {
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
  }

  .cta-button, .info-button {
    width: 100%;
  }
  .cta-bot-button, .info-bot-button {
    width: 100%;
  }
  .hero-section-2 {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .modal-content {
    width: 95%;
    padding: 20px 10px;
  }
  .modal-body {
    flex-direction: column;
  }
  .modal-logo {
    width: 200px;
  }

  h2 {
    font-size: 18px;
  }

  .modal-form p {
    font-size: 12px;
    TEXT-ALIGN: left;
  }

  .form-group input {
    padding: 10px 20px;
    font-size: 12px;
  }

  .subscribe-button {
    padding: 10px 50px;
    font-size: 12px;
  }

  .close-button {
    font-size: 12px;
  }
}
</style>
