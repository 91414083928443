import { createStore } from 'vuex';

export default createStore({
  state: {
    showFooter: true // по умолчанию показываем футер
  },
  mutations: {
    setShowFooter(state, value) {
      state.showFooter = value;
    }
  },
  actions: {
    updateShowFooter({ commit }, value) {
      commit('setShowFooter', value);
    }
  },
  modules: {}
});
