<template>
  <div>
    <ContactContent />
  </div>
</template>

<script>
import ContactContent from '../components/ContactContent.vue';

export default {
  name: 'ContactPage',
  components: {
    ContactContent
  }
};
</script>

<style scoped>
/* ваш стиль */
</style>
