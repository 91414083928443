<template>
  <div class="not-found">
    <h1>404 - Page Not Found</h1>
    <p>The page you are looking for does not exist.</p>
    <router-link to="/">Go to Home</router-link>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style scoped>
.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}

.not-found h1 {
  font-size: 3em;
  margin-bottom: 0.5em;
}

.not-found p {
  font-size: 1.5em;
  margin-bottom: 1em;
}

.not-found a {
  color: #602B84;
  text-decoration: none;
  font-size: 1.2em;
  border: 1px solid #602B84;
  padding: 0.5em 1em;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.not-found a:hover {
  background-color: #602B84;
  color: white;
}
</style>
