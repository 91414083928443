<template>
  <footer>
    <div v-if="isMobile" class="mobile-footer">
      <div class="footer-item-container">
        <div
          class="footer-item"
          :class="{ active: activeItem === 'whatsapp' }"
          @click="setActiveItem('whatsapp')"
        >
          <div class="icon-container whatsapp">
            <img src="@/assets/whatsapp-icon.svg" alt="WhatsApp" />
          </div>
          <span>WHATSAPP</span>
        </div>
        <div
          class="footer-item"
          :class="{ active: activeItem === 'chat' }"
          @click="setActiveItem('chat'); openChat()"
        >
          <div class="icon-container chat">
            <img src="@/assets/chat-icon.svg" alt="Chat" />
          </div>
          <span>CHAT</span>
        </div>
        <div
          class="footer-item enquire"
          :class="{ active: activeItem === 'enquire' }"
          @click="setActiveItem('enquire'); openEnquireForm()"
        >
          <div class="icon-container enquire">
            <img src="@/assets/enquire-icon.svg" alt="Enquire" />
          </div>
          <span>ENQUIRE</span>
        </div>
        <div
          class="footer-item"
          :class="{ active: activeItem === 'call' }"
          @click="setActiveItem('call'); makeCall()"
        >
          <div class="icon-container call">
            <img src="@/assets/call-icon.svg" alt="Call" />
          </div>
          <span>CALL</span>
        </div>
        <div
          class="footer-item"
          :class="{ active: activeItem === 'login' }"
          @click="setActiveItem('login')"
        >
          <div class="icon-container login">
            <img src="@/assets/login-icon.svg" alt="LogIn" />
          </div>
          <span>LOGIN</span>
        </div>
      </div>
    </div>
    <div v-else class="desktop-footer">
      <img
        src="@/assets/Spanglish_Logo-Horizontal-Black_RGB.png"
        alt="Spanglish Logo"
        class="footer-logo"
      />
      <div class="social-buttons">
        <div class="social-btn"><a href="https://www.facebook.com/spanglishvlc">Facebook</a></div>
        <div class="social-btn"><a href="https://www.instagram.com/spanglishvlc">Instagram</a></div>
        <div class="social-btn"><a href="https://www.youtube.com/spanglishvlc">YouTube</a></div>
        <div class="social-btn"><a href="https://wa.me/message/3PV3P3DHDQ3CC1">WhatsApp</a></div>
      </div>
      <p class="footer-rights">All Rights Reserved</p>
    </div>
    <ChatWidget :isChatOpen="isChatOpen" @close-chat="isChatOpen = false" />
    <!-- Модальное окно для формы запроса -->
    <div v-if="showEnquireModal" class="modal-overlay" @click="closeEnquireForm">
      <div class="modal-content" @click.stop>
        <div class="modal-header">
          <h2>{{ $t('subscribeNewsletters') }}</h2>
          <div class="close-button" @click="closeEnquireForm">
            <div class="close-line close-line-1"></div>
            <div class="close-line close-line-2"></div>
          </div>
        </div>
        <div class="modal-body">
          <img src="@/assets/Spanglish_Logo-Vertical-Color_RGB.png" alt="Spanglish Logo" class="modal-logo" />
          <div class="modal-form">
            <p>{{ $t('latestUpdates') }}</p>
            <form @submit.prevent="submitEnquireForm">
              <div class="form-group">
                <input type="email" v-model="enquireEmail" placeholder="Your Email Address" required />
              </div>
              <button type="submit" class="subscribe-button">{{ $t('Subscribe') }}</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import ChatWidget from './ChatWidget.vue';
import axios from 'axios';
export default {
  
  components: {
    ChatWidget
  },
  data() {
    return {
      isMobile: window.innerWidth <= 1024,
      activeItem: '',
      isChatOpen: false,
      showEnquireModal: false, // состояние для отображения модального окна
      enquireEmail: '' // email для формы подписки
    };
  },
  mounted() {
    window.addEventListener('resize', this.checkWindowSize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkWindowSize);
  },
  methods: {
    checkWindowSize() {
      this.isMobile = window.innerWidth <= 1024;
    },
    setActiveItem(item) {
      this.activeItem = item;
      if (item === 'chat') {
        this.openChat();
      } else if (item === 'enquire') {
        this.openEnquireForm();
      } else if (item === 'call') {
        this.makeCall();
      } else if (item === 'whatsapp') {
        this.openWhatsApp();
      } 
      else if (item === 'login') {
        this.openlogin();
      } else {
        this.isChatOpen = false;
        this.showEnquireModal = false;
      }
    },
    openChat() {
      this.isChatOpen = true;
    },
    openEnquireForm() {
      this.showEnquireModal = true; // Открываем модальное окно
    },
    closeEnquireForm() {
      this.showEnquireModal = false; // Закрываем модальное окно
    },
    submitEnquireForm() {
      // Отправка формы подписки
      axios
        .post('/api/subscribe', { email: this.enquireEmail })
        .then(response => {
          console.log(response.data);
          alert('Subscription successful!');
          this.showEnquireModal = false;
          this.enquireEmail = '';
        })
        .catch(error => {
          console.error(error);
          alert('Subscription failed.');
        });
    },
    makeCall() {
      window.location.href = 'tel:+34624114256';
    },
    openWhatsApp() {
      window.open('https://wa.me/message/3PV3P3DHDQ3CC1', '_blank');
    },
    openlogin() {
      window.open('https://elspanglish.es/under-construction', '_blank');
    },
    openInstagram() {
      window.open('https://www.instagram.com/spanglishvlc/', '_blank');
    },
    openFacebook() {
      window.open('https://www.facebook.com/spanglishvlc', '_blank'); // Укажите правильную ссылку на Facebook
    },
    openYouTube() {
      window.open('https://www.youtube.com/spanglishvlc', '_blank'); // Укажите правильную ссылку на YouTube
    }
  }
};
</script>

<style scoped>
footer {
  padding: 10px;
  background-color: #fff;
  color: #333;
}

.mobile-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255);
  position: fixed;
  bottom: 0px;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 70px;
}

.footer-item-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 700px;
  gap: 15px;
}

.footer-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  transition: transform 0.3s;
  position: relative;
}

.icon-container {
  border: 2px solid #602B84;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  top: -10px;
  left: -5px;
  z-index: 1000;
  transition: transform 0.3s, background-color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-item.active .icon-container {
  transform: translateY(-50%) scale(1.5);
  border: 2px solid #fff;
}

.footer-item.active .icon-container.whatsapp {
  background-color: #25d366;
}

.footer-item.active .icon-container.chat,
.footer-item.active .icon-container.enquire,
.footer-item.active .icon-container.login {
  background-color: #FEDA3A;
}

.footer-item.active .icon-container.call {
  background-color: #4da6ff;
}

.footer-item span {
  font-size: 10px;
  position: relative;
  top: -10px;
  left: -4px;
  z-index: 1000;
  margin-top: 5px;
  transition: transform 0.3s, font-size 0.3s;
  align-items: center;
}

.footer-item.active span {
  font-size: 12px;
  color: #333;
  transform: scale(1.2);
}

.footer-item img {
  width: 20px;
  height: 20px;
  transition: transform 0.3s;
}

.footer-item.active img {
  transform: scale(1.2);
}

.desktop-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  max-width: 1000px;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  gap: 50px;
}

.footer-logo {
  width: 150px;
  height: auto;
  margin-left:-85px;
}
.social-buttons {
    width: 100%; /* Убедитесь, что контейнер занимает всю доступную ширину */
    display: flex;
    flex-wrap: nowrap; /* Устанавливаем в один ряд */
    justify-content: space-between; /* Равномерное распределение */
    margin-top: -20px; /* Добавляем отступ сверху для разделения от логотипа */
}

.social-buttons .social-btn {
    position: relative;
    top: 0;
    left: 0;
    width: 150px; /* Уменьшенная ширина кнопки */
    height: 40px; /* Уменьшенная высота кнопки */
    margin: 0 5px; /* Небольшой отступ между кнопками */
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-buttons .social-btn a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 50px; /* Уменьшаем радиус скругления */
    padding: 5px; /* Уменьшаем внутренний отступ */
    letter-spacing: 1px;
    text-decoration: none;
    overflow: hidden;
    color: black;
    font-weight: 400;
    z-index: 1;
    transition: 0.5s;
    backdrop-filter: blur(15px);
}

.social-buttons .social-btn:hover a {
    letter-spacing: 2px; /* Немного уменьшаем эффект при наведении */
}

.social-buttons .social-btn a::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
    transform: skewX(45deg) translate(0);
    transition: 0.5s;
    filter: blur(0px);
}

.social-buttons .social-btn:hover a::before {
    transform: skewX(45deg) translate(150px); /* Скорректированный эффект при наведении */
}

.social-buttons .social-btn::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -5px;
    width: 20px; /* Уменьшена ширина светового эффекта */
    height: 8px; /* Уменьшена высота светового эффекта */
    background: #f00;
    border-radius: 8px; /* Скорректированный радиус */
    transition: 0.5s;
    transition-delay: 0.5s;
}

.social-buttons .social-btn:hover::before {
    bottom: 0;
    height: 40%;
    width: 70%;
    border-radius: 20px;
}

.social-buttons .social-btn::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -5px;
    width: 20px; /* Уменьшена ширина светового эффекта */
    height: 8px; /* Уменьшена высота светового эффекта */
    background: #f00;
    border-radius: 8px; /* Скорректированный радиус */
    transition: 0.5s;
    transition-delay: 0.5s;
}

.social-buttons .social-btn:hover::after {
    top: 0;
    height: 40%;
    width: 70%;
    border-radius: 20px;
}

.social-buttons .social-btn:nth-child(1)::before,
.social-buttons .social-btn:nth-child(1)::after {
    background: #1877F2;
    box-shadow: 0 0 5px #1877F2, 0 0 15px #1877F2, 0 0 30px #1877F2,
        0 0 60px #1877F2f;
}

.social-buttons .social-btn:nth-child(2)::before,
.social-buttons .social-btn:nth-child(2)::after {
    background: #602B84;
    box-shadow: 0 0 5px #602B84, 0 0 15px #602B84, 0 0 30px #602B84,
        0 0 60px #602B84;
}

.social-buttons .social-btn:nth-child(3)::before,
.social-buttons .social-btn:nth-child(3)::after {
    background: #d71e18;
    box-shadow: 0 0 5px#d71e18, 0 0 15px #d71e18, 0 0 30px#d71e18,
        0 0 60px #d71e18;
}

.social-buttons .social-btn:nth-child(4)::before,
.social-buttons .social-btn:nth-child(4)::after {
    background: #1eff45;
    box-shadow: 0 0 5px #1eff45, 0 0 15px #1eff45, 0 0 30px #1eff45,
        0 0 60px #1eff45;
}
img.footer-logo {
    width: 200px;
}

.footer-rights {
  font-size: 14px;
  color: black;
  margin-right: -120px;
  padding: 25px;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8); /* Полупрозрачный фон */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.modal-content {
  background: white;
  padding: 40px 20px;
  border-radius: 20px;
  max-width: 600px;
  width: 90%;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-logo {
  width: 200px;
  margin-right: 20px;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-form {
  flex: 1;
  width: 100%;
}

.modal-form p {
  margin-bottom: 15px;
  font-size: 20px;
  text-align: center;
}

.form-group {
  margin-bottom: 15px;
  position: relative;
  width: 100%;
}

.form-group input {
  width: 100%;
  padding: 10px 15px;
  border-radius: 30px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-size: 16px;
  background-color: white;
  position: relative;
  z-index: 1;
}

.subscribe-button {
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  background-image: linear-gradient(to right, #ee322e, #feda3a);
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.subscribe-button:hover {
  background-image: linear-gradient(to right, #feda3a, #ee322e);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.close-line {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 2px;
  background: #ee322e;
  transform: translate(-50%, -50%) rotate(45deg);
}

.close-line-2 {
  background: #602b84;
  transform: translate(-50%, -50%) rotate(-45deg);
}
</style>
