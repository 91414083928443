
<template>
  <div class="cursos-page">
    <div class="header-wrapper">
      <SecHeader />
    </div>
    <div class="background-section">
      <img
        src="@/assets/1_courses.webp"
        alt="Courses Background"
        class="background-image"
      />
    </div>
    <div class="content-container">
      <div class="dropdown-container">
        <label for="program-select">{{ $t('Programas para:') }}</label>
        <div class="custom-select-wrapper">
          <select
            id="program-select"
            class="custom-select"
            v-model="selectedProgram"
          >
            <option value="todas">{{ $t('Todas las edades') }}</option>
            <option value="primaria">
              {{ $t('Estudiantes de primaria y secundaria (10-16 años)') }}
            </option>
            <option value="bachillerato">
              {{ $t('Estudiantes de bachillerato (16-18 años)') }}
            </option>
            <option value="universitarios">
              {{ $t('Universitarios y adultos (18-25 años)') }}
            </option>
            <option value="adultos25">{{ $t('Adultos 25+ (25+ años)') }}</option>
            <option value="adultos50">{{ $t('Adultos 50+ (50+ años)') }}</option>
          </select>
        </div>
      </div>
      <div class="courses-container">
        <div
          class="course-card"
          v-for="course in filteredCourses"
          :key="course.title"
        >
          <img :src="course.image" :alt="course.title" />
          <div class="course-info">
            <h2>{{ course.title }}</h2>
            <p>{{ course.description }}</p>
            <div class="course-details">
              <span class="course-duration"
                ><i class="fa fa-calendar"></i> {{ course.duration }}</span
              >
            </div>
            <div class="course-buttons">
              <button class="cta-button" @click="showModal = true">
                {{ $t('catalogueButton') }}
              </button>
              <button class="info-button" @click="openCourseModal(course)">
                {{ $t('moreInfoButton') }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="background-section-2">
      <img
        src="@/assets/2_courses.webp"
        alt="2 Courses Background"
        class="background-image-2"
      />
      <div class="hero-section-2">
        <button class="cta-bot-button" @click="showModal = true">
          {{ $t('catalogueButton') }}
        </button>
        <button class="info-bot-button" @click="navigateToUnderConstruction">
          {{ $t('moreInfoButton') }}
        </button>
      </div>
    </div>
    <!-- Модальное окно для подписки -->
    <div v-if="showModal" class="modal-overlay" @click="closeModal">
      <div class="modal-content" @click.stop>
        <div class="modal-header">
          <h2>{{ $t('subscribeNewsletters') }}</h2>
          <div class="close-button" @click="closeModal">
            <div class="close-line close-line-1"></div>
            <div class="close-line close-line-2"></div>
          </div>
        </div>
        <div class="modal-body">
          <img
            src="@/assets/Spanglish_Logo-Vertical-Color_RGB.png"
            alt="Spanglish Logo"
            class="modal-logo"
          />
          <div class="modal-form">
            <p>{{ $t('latestUpdates') }}</p>
            <form @submit.prevent="submitForm">
              <div class="form-group">
                <input
                  type="email"
                  v-model="email"
                  placeholder="Your Email Address"
                  required
                />
              </div>
              <button type="submit" class="subscribe-button">
                {{ $t('Subscribe') }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Модальное окно с информацией о курсе -->
    <div v-if="showCourseModal" class="modal-overlay" @click="closeCourseModal">
      <div class="modal-content" @click.stop>
        <div class="modal-header">
          <h2>{{ currentCourse.title }}</h2>
          <div class="close-button" @click="closeCourseModal">
            <div class="close-line close-line-1"></div>
            <div class="close-line close-line-2"></div>
          </div>
        </div>
        <div class="modal-body">
          <!-- Контроль отображения изображения и описания -->
          <div v-if="!hideImageAndDescription">
            <img
              :src="currentCourse.image"
              :alt="currentCourse.title"
              class="modal-course-image"
            />
            <p>{{ currentCourse.description }}</p>
            <!-- Селектор выбора курса -->
            <div v-if="currentCourseOptions.length > 0" class="course-selector">
              <label for="course-select">{{ $t('Selectacourse') }}</label>
              <select id="course-select" v-model="selectedCourseOption">
                <option
                  v-for="option in currentCourseOptions"
                  :key="option.title"
                  :value="option.title"
                >
                  {{ option.title }}
                </option>
              </select>
              <!-- Описание выбранного курса -->
              <p v-if="selectedCourseOption">
                {{
                  currentCourseOptions.find(
                    (option) => option.title === selectedCourseOption
                  ).description
                }}
              </p>
            </div>
          </div>
          <div class="course-buttons">
            <button class="cta-button" @click="togglePhoneNumberInput">
              {{ $t('Scheduleacall') }}
            </button>
            <button class="cta-button" @click="bookCourseOnline">
              {{ $t('Bookacourseonline') }}
            </button>
          </div>
          <div v-if="showPhoneNumberInput" class="call-to-action">
            <p>{{ $t('bookACall') }}</p>
            <div class="input-container-name">
              <input
                v-model="userName"
                type="text"
                :placeholder="$t('Yourname')"
                required
              />
            </div>
            <div class="input-container">
              <div class="custom-select-wrapper">
                <select
                  v-model="selectedCountryCode"
                  class="country-code custom-select"
                >
                  <option value="+1">+1 (US)</option>
                  <option value="+44">+44 (UK)</option>
                  <option value="+33">+33 (FR)</option>
                  <option value="+49">+49 (DE)</option>
                  <option value="+7">+7 (RU)</option>
                  <option value="+43">+43 (Austria)</option>
                  <option value="+32">+32 (Belgium)</option>
                  <option value="+359">+359 (Bulgaria)</option>
                  <option value="+385">+385 (Croatia)</option>
                  <option value="+357">+357 (Cyprus)</option>
                  <option value="+420">+420 (Czech Republic)</option>
                  <option value="+45">+45 (Denmark)</option>
                  <option value="+372">+372 (Estonia)</option>
                  <option value="+358">+358 (Finland)</option>
                  <option value="+30">+30 (Greece)</option>
                  <option value="+36">+36 (Hungary)</option>
                  <option value="+354">+354 (Iceland)</option>
                  <option value="+353">+353 (Ireland)</option>
                  <option value="+39">+39 (Italy)</option>
                  <option value="+371">+371 (Latvia)</option>
                  <option value="+370">+370 (Lithuania)</option>
                  <option value="+352">+352 (Luxembourg)</option>
                  <option value="+356">+356 (Malta)</option>
                  <option value="+31">+31 (Netherlands)</option>
                  <option value="+47">+47 (Norway)</option>
                  <option value="+48">+48 (Poland)</option>
                  <option value="+351">+351 (Portugal)</option>
                  <option value="+40">+40 (Romania)</option>
                  <option value="+421">+421 (Slovakia)</option>
                  <option value="+386">+386 (Slovenia)</option>
                  <option value="+34">+34 (Spain)</option>
                  <option value="+46">+46 (Sweden)</option>
                  <option value="+41">+41 (Switzerland)</option>
                  <option value="+90">+90 (Turkey)</option>
                </select>
              </div>
              <input
                v-model="userPhoneNumber"
                type="tel"
                :placeholder="$t('phonePlaceholder')"
                :maxlength="getPhoneNumberMaxLength(selectedCountryCode)"
                pattern="[0-9]*"
                @input="validatePhoneNumber"
                required
              />
              <button @click="sendPhoneNumber"><span>&rarr;</span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SecHeader from "./SecHeader.vue";
import axios from "axios";

export default {
  components: {
    SecHeader,
  },
  data() {
    return {
      selectedProgram: "todas",
      courses: [],
      showModal: false,
      showCourseModal: false, // для управления состоянием модального окна курса
      showPhoneNumberInput: false, // для управления состоянием отображения поля ввода номера телефона
      hideImageAndDescription: false, // состояние для скрытия изображения и описания
      currentCourse: {}, // хранит информацию о текущем выбранном курсе
      email: "",
      selectedCountryCode: "+34", // Default country code
      userPhoneNumber: "",
      userName: "", // Имя пользователя
      selectedCourseOption: "", // выбранный курс в селекторе
    };
  },
  created() {
    this.setCourses();
  },
  watch: {
    "$i18n.locale": "setCourses",
  },
  methods: {
    setCourses() {
      this.courses = [
        {
          title: this.$t("spanishCourses"),
          description: this.$t("spanishCoursesDesc"),
          image: require("@/assets/spanish-courses.jpg"),
          duration: "2-24 semanas",
          ageCategory: "primaria",
        },
        {
          title: this.$t("englishCourses"),
          description: this.$t("englishCoursesDesc"),
          image: require("@/assets/english-courses.jpg"),
          duration: "2-24 semanas",
          ageCategory: "universitarios",
        },
        {
          title: this.$t("coursesAbroad"),
          description: this.$t("coursesAbroadDesc"),
          image: require("@/assets/courses-abroad.jpg"),
          duration: "2-24 semanas",
          ageCategory: "adultos25",
        },
      ];
    },
    submitForm() {
      const language = this.$i18n.locale; // Получаем текущий язык

      axios
        .post("/api/coursesubscribe", { email: this.email, language })
        .then((response) => {
          console.log(response.data);
          alert("Subscription successful!");
          this.showModal = false;
          this.email = "";
        })
        .catch((error) => {
          console.error(error);
          alert("Subscription failed.");
        });
    },
    navigateToUnderConstruction() {
      this.$router.push("/under-construction");
    },
    openCourseModal(course) {
      this.currentCourse = course;
      this.showCourseModal = true;
      this.hideImageAndDescription = false; // сбрасываем состояние при открытии модального окна
    },
    closeModal() {
      this.showModal = false;
      this.selectedCourseOption = ""; // Reset the course selector
    },
    closeCourseModal() {
      this.showCourseModal = false;
      this.selectedCourseOption = ""; // Reset the course selector
    },
    togglePhoneNumberInput() {
      this.showPhoneNumberInput = !this.showPhoneNumberInput;
      this.hideImageAndDescription = this.showPhoneNumberInput; // переключаем видимость изображения и текста
    },
    getPhoneNumberMaxLength(countryCode) {
      // Определяем максимальную длину номера в зависимости от кода страны
      const maxLengthMap = {
        "+1": 10, // USA
        "+44": 10, // UK
        "+33": 9, // France
        "+49": 10, // Germany
        "+7": 10, // Russia
        "+34": 9, // Spain
        // Добавьте другие страны по необходимости
      };
      return maxLengthMap[countryCode] || 10; // По умолчанию 10
    },
    validatePhoneNumber() {
      // Удаляем все символы, кроме цифр
      this.userPhoneNumber = this.userPhoneNumber.replace(/\D/g, "");
    },
    async sendPhoneNumber() {
      const phoneNumber = `${this.selectedCountryCode} ${this.userPhoneNumber}`;
      const selectedCourseTitle = this.selectedCourseOption || "";
      try {
        const response = await fetch("/api/sendPhoneNumber", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: this.userName,
            phoneNumber,
            course: selectedCourseTitle,
          }),
        });
        if (response.ok) {
          alert(this.$t('phoneNumberSentSuccess'));
          this.userPhoneNumber = "";
          this.userName = "";
          this.selectedCourseOption = "";
        } else {
          const errorText = await response.text();
          console.error(this.$t('phoneNumberSendingError'), errorText);
          alert(this.$t('phoneNumberSendingErrorTryLater'));
        }
      } catch (error) {
        console.error(this.$t('phoneNumberSendingError'), error);
        alert(this.$t('phoneNumberSendingErrorTryLater'));
      }
    },
    bookCourseOnline() {
      alert(this.$t('Bookacourseonline'));
      // Добавьте здесь логику для бронирования курса
    },
  },
  computed: {
    filteredCourses() {
      if (this.selectedProgram === "todas") {
        return this.courses;
      }
      return this.courses.filter(
        (course) => course.ageCategory === this.selectedProgram
      );
    },
    currentCourseOptions() {
      // Возвращаем список курсов для текущего языка
      if (this.currentCourse.title === this.$t("spanishCourses")) {
        return [
          {
            title: this.$t("SpaincourseOptions.semiIntensive.title"),
            description: this.$t("SpaincourseOptions.semiIntensive.description"),
          },
          {
            title: this.$t("SpaincourseOptions.partTime.title"),
            description: this.$t("SpaincourseOptions.partTime.description"),
          },
          {
            title: this.$t("SpaincourseOptions.specializedClasses.title"),
            description: this.$t("SpaincourseOptions.specializedClasses.description"),
          },
          {
            title: this.$t("SpaincourseOptions.privateLessons.title"),
            description: this.$t("SpaincourseOptions.privateLessons.description"),
          },
          {
            title: this.$t("SpaincourseOptions.delePreparation.title"),
            description: this.$t("SpaincourseOptions.delePreparation.description"),
          },
          {
            title: this.$t("SpaincourseOptions.kidsAndTeens.title"),
            description: this.$t("SpaincourseOptions.kidsAndTeens.description"),
          },
          {
            title: this.$t("SpaincourseOptions.urbanCamps.title"),
            description: this.$t("SpaincourseOptions.urbanCamps.description"),
          },
          {
            title: this.$t("SpaincourseOptions.theaterWorkshops.title"),
            description: this.$t("SpaincourseOptions.theaterWorkshops.description"),
          },
          {
            title: this.$t("SpaincourseOptions.nativeSpeakerTheater.title"),
            description: this.$t("SpaincourseOptions.nativeSpeakerTheater.description"),
          },
          {
            title: this.$t("SpaincourseOptions.creativeWriting.title"),
            description: this.$t("SpaincourseOptions.creativeWriting.description"),
          },
        ];
      } else if (this.currentCourse.title === this.$t("englishCourses")) {
        return [
          {
            title: this.$t("EngcourseOptions.generalEnglishCourse.title"),
            description: this.$t("EngcourseOptions.generalEnglishCourse.description"),
          },
          {
            title: this.$t("EngcourseOptions.cursoCambridge.title"),
            description: this.$t("EngcourseOptions.cursoCambridge.description"),
          },
        ];
      } else if (this.currentCourseTitle === this.$t("coursesAbroad")) {
        return [
          {
            title: this.$t("AbroadcourseOptions.generalEnglish.title"),
            description: this.$t("AbroadcourseOptions.generalEnglish.description"),
          },
          {
            title: this.$t("AbroadcourseOptions.intensiveEnglish.title"),
            description: this.$t("AbroadcourseOptions.intensiveEnglish.description"),
          },
          {
            title: this.$t("AbroadcourseOptions.summerJuniorCourses.title"),
            description: this.$t("AbroadcourseOptions.summerJuniorCourses.description"),
          },
        ];
      }
      return [];
    },
  },
};
</script>




<style scoped>
/* существующие стили */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

body {
  margin: 0;
  padding: 0;
  font-family: 'DM Sans', sans-serif;
  overflow-x: hidden;
}

.cursos-page {
  width: 100%;
}

.header-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}
.background-section {
  width: 100%;
  height: 60vh; /* Высота 60% от высоты экрана */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}
  
.background-section-2 {
  width: 100%;
  height: 450px; /* Можно настроить высоту под ваши нужды */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.background-section-2::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Полупрозрачный черный слой */
}
.hero-section-2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  gap: 550px;
} 

  
.background-image-2 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-container {
   margin-top: 40px;
   margin-bottom: 40px; /* Отступ сверху для размещения под фиксированным хедером */
}

.dropdown-container {
  text-align: center;
  margin-bottom: 20px;
}

.dropdown-container label {
  display: block;
  font-size: 16px;
  margin-bottom: 8px;
}

.dropdown-container select {
  width: 100%;
  max-width: 400px;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin: 0 auto;
}
.custom-select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 400px;
}

.custom-select {
  appearance: none;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: white;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24"><path fill="%23ccc" d="M12 15l-8-8h16z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 12px 12px;
}

.custom-select:focus {
  border-color: #EE322E;
  outline: none;
}
.courses-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.course-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  padding: 20px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-width: 800px;
  width: 100%;
  transition: transform 0.3s, box-shadow 0.3s;
}

.course-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.course-card img {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  margin-right: 20px;
  object-fit: cover;
}

.course-info {
  flex: 1;
}

.course-info h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: black;
  font-weight: bold;
}

.course-info p {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
}

.course-details {
  font-size: 14px;
  color: #555;
  margin-bottom: 10px;
}

.course-details span {
  margin-right: 15px;
}

.course-buttons {
  display: flex;
  gap: 10px;
}

.cta-button {
  padding: 10px 20px;
  border: 2px solid #EE322E;
  border-radius: 25px;
  background: none;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #EE322E;
}

.cta-button:hover {
  background:  #EE322E;
  color: white;
}

.info-button {
  padding: 10px 20px;
  border: 2px solid #EE322E;
  border-radius: 25px;
  background: #EE322E;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;
}

.info-button:hover {
  background: none;
  border-color:  #EE322E;
  color: #EE322E;
}
.cta-bot-button {
  padding: 10px 20px;
  border: 2px solid #EE322E;
  border-radius: 25px;
  background: #EE322E;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;
  white-space: nowrap;
}

.cta-bot-button:hover {
  background: none;
  color: white;
  border: 2px solid #EE322E;
}

.info-bot-button {
  padding: 10px 20px;
  border: 2px solid #FEDA3A;
  border-radius: 25px;
  background:none;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #FEDA3A;
  white-space: nowrap;
}

.info-bot-button:hover {
  background: #FEDA3A;
  border-color: #333;
  color:#333;
}


/* Стили для модального окна */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  overflow-y: auto; /* Добавляем прокрутку для overlay */
}
.modal-content {
  background: white;
  padding: 40px 20px;
  border-radius: 20px;
  max-width: 600px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  text-align: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.modal-logo {
  width: 200px;
  margin-right: 20px;
}

.modal-course-image {
  width: 300px;
  height: 200px;
  border-radius: 15px; /* Округлые края */
  object-fit: cover;
  margin-bottom: 20px;
}

h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.modal-body {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.modal-form {
  flex: 1;
}

.modal-form p {
  margin-bottom: 15px;
  font-size: 20px;
  TEXT-ALIGN: left;
}

.form-group {
  margin-bottom: 15px;
  position: relative;
}

.form-group input {
  width: 100%;
  padding: 10px 15px;
  border-radius: 30px;
  border: none; /* Убираем стандартный бордер */
  box-sizing: border-box;
  font-size: 16px;
  background-color: white; /* Добавляем белый фон для инпута */
  position: relative;
  z-index: 1; /* Устанавливаем z-index для правильного наложения псевдоэлемента */
}

.form-group::before {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  right: -3px;
  bottom: -3px;
  border-radius: 30px;
  background: linear-gradient(to right, #EE322E, #FEDA3A);
  z-index: 0; /* Устанавливаем z-index ниже, чем у инпута */
  transition: opacity 0.3s ease; /* Плавный переход для скрытия бордера */
}

.form-group input:focus::before {
  opacity: 0; /* Скрываем бордер при фокусе */
}

.subscribe-button {
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  background-image: linear-gradient(to right, #EE322E, #FEDA3A);
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.subscribe-button:hover {
  background-image: linear-gradient(to right, #FEDA3A, #EE322E);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.close-line {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 2px;
  background: #EE322E;
  transform: translate(-50%, -50%) rotate(45deg);
}

.close-line-2 {
  background: #602B84;
  transform: translate(-50%, -50%) rotate(-45deg);
}

.call-to-action {
  margin-top: 20px;
}

.call-to-action p {
  color: black;
  font-size: 18px;
  font-weight: 400; /* DM Sans Regular */
}

.input-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.input-container select.country-code {
  padding: 10px;
  border: none;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: white;
  cursor: pointer;
  height: 42px;
  width: 90px;
  border: 1px solid #ee322e;
}

.input-container input {
  padding: 10px;
  border: none;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  flex: 1;
  height: 20px;
  border: 1px solid #ee322e;
}
.input-container-name input {
  padding: 10px;
  border: none;
  border-radius: 30px;
  flex: 1;
  height: 20px;
  border: 1px solid #ee322e;
}
.input-container button {
  padding: 10px;
  background-color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  border: 1px solid #ee322e;
}

.input-container button span {
  font-size: 20px;
  color: #EE322E;
}
.course-selector {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 15%;
  width: 100%;
  max-width: 400px;
  font-family: 'DM Sans', sans-serif;
}

.course-selector label {
  display: block;
  font-size: 16px;
  margin-bottom: 8px;
}

.course-selector select {
  appearance: none;
  width: 100%;
  padding: 12px;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid #EE322E;
  background-color: white;
  font-family: 'DM Sans', sans-serif;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24"><path fill="%23EE322E" d="M12 15l-8-8h16z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 12px 12px;
  transition: border-color 0.3s ease;
}

.course-selector select:focus {
  border-color: #FEDA3A;
  outline: none;
}

.course-selector p {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
  line-height: 1.5;
  white-space: pre-wrap;
  text-align: left;
  font-family: 'DM Sans', sans-serif;
}

@media (max-width: 1200px) {
  .hero-section-2 {
    gap: 250px;
  }
  .course-card {
    flex-direction: column;
    align-items: center;
  }

  .course-card img {
    width: 100%;
    max-width: 300px;
    margin-right: 0;
    margin-bottom: 10px;
  }
}

/* Адаптивность для планшетов */
@media (max-width: 768px) {
  .background-section { 
    height: 60vh;; /* Можно настроить высоту под ваши нужды */
  }
  .background-section-2 { 
    height: 400px; /* Можно настроить высоту под ваши нужды */
  }
  .course-card {
    flex-direction: column;
    text-align: center;
  }
  .course-selector {
    width: 90%;
    margin-left: 7%;
    font-family: 'DM Sans', sans-serif;
  }
  .course-card img {
    width: 150px;
    height: 150px;
    border-radius: 10px;
    margin-right: 20px;
    object-fit: cover;
  }


  .course-info {
    margin-top: 10px;
  }

  .course-buttons {
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
  }

  .cta-button, .info-button {
    width: 100%;
    max-width: 300px;
  }
  .cta-bot-button, .info-bot-button {
    width: 100%;
    max-width: 300px;
  }
  .hero-section-2 {
    gap: 30px;
  }
   .modal-content {
    width: 80%;
    padding: 30px 15px;
    overflow-y: auto;
    max-height: 70vh;
    overflow-x: hidden
  }

  .modal-logo {
    width: 200px;
  }

  h2 {
    font-size: 20px;
  }

  .modal-form p {
    font-size: 14px;
    TEXT-ALIGN: left;
  }

  .form-group input {
    padding: 10px 20px;
    font-size: 14px;
  }

  .subscribe-button {
    padding: 10px 50px;
    font-size: 14px;
  }

  .close-button {
    font-size: 14px;
  }
}

/* Адаптивность для мобильных устройств */
@media (max-width: 480px) {
  .course-card {
    width: 90%;
  }

  .background-section { 
    height: 60vh; /* Можно настроить высоту под ваши нужды */
  }
  .background-section-2 { 
    height: 300px; /* Можно настроить высоту под ваши нужды */
  }
  .content-container h1 {
    font-size: 28px;
  }
  .course-card img {
    width: 150px;
    height: 150px;
    border-radius: 10px;
    margin-right: 20px;
    object-fit: cover;
  }
  .course-selector {
    width: 80%;
    margin-left: 10%;
    font-family: 'DM Sans', sans-serif;
  }
  .dropdown-container select {
    width: 80%;
    padding: 8px;
    font-size: 14px;
  }

  .course-card {
    padding: 10px;
  }

  .course-info h2 {
    font-size: 20px;
  }

  .course-info p {
    font-size: 14px;
  }

  .course-buttons {
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
  }

  .cta-button, .info-button {
    width: 100%;
  }
  .cta-bot-button, .info-bot-button {
    width: 100%;
  }
  .hero-section-2 {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  .modal-content {
    width: 85%;
    padding: 20px 10px;
    max-height: 80vh;
    overflow-y: auto;
    overflow-x: hidden
  }
  .modal-body {
    flex-direction: column;
  }
  .modal-logo {
    width: 200px;
  }

  h2 {
    font-size: 18px;
  }

  .modal-form p {
    font-size: 12px;
    TEXT-ALIGN: left;
  }

  .form-group input {
    padding: 10px 20px;
    font-size: 12px;
  }

  .subscribe-button {
    padding: 10px 50px;
    font-size: 12px;
  }

  .close-button {
    font-size: 12px;
  }
  .modal-body[hide-image-description] .modal-course-image,
  .modal-body[hide-image-description] p {
    display: none;
  }
}
</style>
