<template>
  <div class="loading-screen" v-if="loading">
    <div class="loading-container">
      <img src="../assets/Logo-color.png" alt="Logo" class="logo" />
      <div class="track">
        <div class="levels">
          <div class="level" v-for="(level, index) in levels" :key="index">
            <span>{{ level }}</span>
            <div v-if="index < levels.length - 1" class="separator"></div>
          </div>
        </div>
        <div class="progress-bar" :style="{ width: progressBarWidth + '%' }"></div>
        <div class="runner" :style="{ left: runnerPosition + '%' }">
          <img src="@/assets/student.gif" alt="Runner" />
        </div>
      </div>
      <div class="loading-text">Mejoramos tu nivel del idioma...</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      levels: ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
      progressBarWidth: 0,
      runnerPosition: 0,
    };
  },
  mounted() {
    this.animateLoading();
  },
  methods: {
    animateLoading() {
      let position = 0;
      const interval = setInterval(() => {
        if (position >= 100) {
          clearInterval(interval);
          this.loading = false;
          this.$emit('loading-complete');
        } else {
          position += 15; // Уменьшаем скорость анимации
          this.progressBarWidth = position;
          this.runnerPosition = position;
        }
      }, 100); // Увеличиваем интервал для замедления
    },
  },
};
</script>

<style scoped>
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  z-index: 1000;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  position: relative;
}

.logo {
  height: 300px;
  margin-bottom: 20px;
}

.track {
  position: relative;
  height: 50px;
  width: 100%;
  background: #e0e0e0;
  border-radius: 25px;
  overflow: hidden;
}

.levels {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.level {
  position: relative;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  flex: 1;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FEDA3A; /* Цвет уровня */
}

.separator {
  height: 100%;
  width: 2px;
  background: #fff;
  position: absolute;
  right: 0;
  top: 0;
}

.progress-bar {
  position: absolute;
  height: 100%;
  background: linear-gradient(to right, #EE322E, #602B84);
  transition: width 0.1s linear;
  z-index: 0;
}

.runner {
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  transition: left 0.1s linear;
  z-index: 2;
}

.runner img {
  height: 50px;
}

.loading-text {
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  color: #000;
}

@media (max-width: 768px) {
  .loading-container {
    width: 90%;
  }

  .logo {
    height: 200px;
  }

  .track {
    height: 40px;
  }

  .level {
    font-size: 12px;
    line-height: 40px;
  }

  .runner img {
    height: 40px;
  }

  .loading-text {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .loading-container {
    width: 95%;
  }

  .logo {
    height: 150px;
  }

  .track {
    height: 30px;
  }

  .level {
    font-size: 10px;
    line-height: 30px;
  }

  .runner img {
    height: 30px;
  }

  .loading-text {
    font-size: 14px;
  }
}
</style>
