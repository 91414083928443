<template>
  <div class="contact-page">
    <div class="header-wrapper">
      <SecHeader />
    </div>
    <div class="background-section">
      <img src="@/assets/1_contact.jpg" alt="Booking Background" class="background-image" />
    </div>
    <div class="contact-content">
      <h1>{{ $t('ContactTitle') }}</h1>
      <div class="contact-form-container">
        <div class="contact-form">
          <div class="contact-info">
            <label for="name">{{ $t('name') }}</label>
            <input
              type="text"
              id="name"
              v-model="name"
              :style="{ borderColor: nameError ? 'red' : '#602b84' }"
              class="input-field"
              required
            />
            
            <label for="email">{{ $t('email') }}</label>
            <input
              type="email"
              id="email"
              v-model="email"
              :class="{ 'input-error': emailError }"
              class="input-field"
              required
              @blur="validateEmail"
            />
            <span v-if="emailError" class="error-message">{{ $t('InvalidEmail') }}</span>
            <div class="phone-input-container">
              <label for="phone">{{ $t('PhoneNumber') }}</label>
              <div class="input-container">
                <div class="custom-select-wrapper">
                  <div class="selected-option" @click="toggleDropdown">
                    <img :src="selectedFlag" alt="Flag" class="flag" />
                  </div>
                  <div v-if="isDropdownOpen" class="options">
                    <div
                      v-for="(country, index) in countries"
                      :key="country.code"
                      @click="selectCountry(country)"
                      class="option"
                      :class="{ 'with-divider': index !== countries.length - 1 }"
                    >
                      <img :src="country.flag" alt="Flag" class="flag" />
                    </div>
                  </div>
                </div>
                <input
                  v-model="userPhoneNumber"
                  type="tel"
                  :placeholder="$t('phonePlaceholder')"
                  :maxlength="getPhoneNumberMaxLength(selectedCountryCode)"
                  :class="{ 'input-error': phoneError, 'custom-phone-input': true }"
                  pattern="[0-9]*"
                  @input="validatePhoneNumber"
                  required
                />
              </div>
              <span v-if="phoneError" class="error-message">{{ $t('InvalidPhoneNumber') }}</span>
            </div>
            <label for="text">{{ $t('text') }}</label>
            <textarea
              type="text"
              id="text"
              v-model="text"
              :class="{ 'input-error': textError }"
              class="text-field"
              required
            />
          </div>
          <button @click="contactus" class="book-button">
            {{ $t('ContactNow') }}
          </button>
        </div>
      </div>
    </div>        
    <h2>{{ $t('findus') }}</h2>
    <div id="map" class="map-container"></div>      
    <h2>{{ $t('emailus') }}</h2>
    <h3>
      {{ $t('emailustwo') }} 
      <a href="mailto:info@elspanglish.es" class="email-link">info@elspanglish.es</a>
    </h3>
    <h3>
      {{ $t('adrestex') }} 
      <a href="tel:+34962076900" class="phone-link">+34 624 114 256</a>
    </h3>        
    <section class="newsletter-section">
      <div class="newsletter-content">
        <h2>{{ $t('subscribeNewsletters') }}</h2>
        <p>{{ $t('latestUpdates') }}</p>
      </div>
      <div class="newsletter-input-container">
        <input type="email" v-model="email" :placeholder="$t('yourEmailAddress')" class="email-input" />
        <button class="subscribe_bot-button" @click="subscribe"><span>&rarr;</span></button>
      </div>
    </section>
  </div>
</template>

<script>
import SecHeader from './SecHeader.vue';
import '@vuepic/vue-datepicker/dist/main.css';
/* global google */

export default {
  components: {
    SecHeader,
  },
  data() {
    return {
      name: '',
      email: '',
      text: '',
      userPhoneNumber: '',
      selectedCountryCode: '', // Default to Spain
      selectedFlag: require("@/assets/unknown-flag.svg"),
      isDropdownOpen: false,
      nameError: false,
      emailError: '',
      textError: false,
      phoneError: false,
      countries: [
        { code: "+1", name: "US", flag: require("@/assets/us-flag.png") },
        { code: "+44", name: "UK", flag: require("@/assets/uk-flag.png") },
        { code: "+33", name: "France", flag: require("@/assets/fr-flag.png") },
        { code: "+49", name: "Germany", flag: require("@/assets/de-flag.png") },
        { code: "+7", name: "Russia", flag: require("@/assets/ru-flag.png") },
        { code: "+43", name: "Austria", flag: require("@/assets/at-flag.png") },
        { code: "+32", name: "Belgium", flag: require("@/assets/be-flag.png") },
        { code: "+359", name: "Bulgaria", flag: require("@/assets/bg-flag.png") },
        { code: "+385", name: "Croatia", flag: require("@/assets/hr-flag.png") },
        { code: "+357", name: "Cyprus", flag: require("@/assets/cy-flag.png") },
        { code: "+420", name: "Czech Republic", flag: require("@/assets/cz-flag.png") },
        { code: "+45", name: "Denmark", flag: require("@/assets/dk-flag.png") },
        { code: "+372", name: "Estonia", flag: require("@/assets/ee-flag.png") },
        { code: "+358", name: "Finland", flag: require("@/assets/fi-flag.png") },
        { code: "+30", name: "Greece", flag: require("@/assets/gr-flag.png") },
        { code: "+36", name: "Hungary", flag: require("@/assets/hu-flag.png") },
        { code: "+354", name: "Iceland", flag: require("@/assets/is-flag.png") },
        { code: "+353", name: "Ireland", flag: require("@/assets/ie-flag.png") },
        { code: "+39", name: "Italy", flag: require("@/assets/it-flag.png") },
        { code: "+371", name: "Latvia", flag: require("@/assets/lv-flag.png") },
        { code: "+370", name: "Lithuania", flag: require("@/assets/lt-flag.png") },
        { code: "+352", name: "Luxembourg", flag: require("@/assets/lu-flag.png") },
        { code: "+356", name: "Malta", flag: require("@/assets/mt-flag.png") },
        { code: "+31", name: "Netherlands", flag: require("@/assets/nl-flag.png") },
        { code: "+47", name: "Norway", flag: require("@/assets/no-flag.png") },
        { code: "+48", name: "Poland", flag: require("@/assets/pl-flag.png") },
        { code: "+351", name: "Portugal", flag: require("@/assets/pt-flag.png") },
        { code: "+40", name: "Romania", flag: require("@/assets/ro-flag.png") },
        { code: "+421", name: "Slovakia", flag: require("@/assets/sk-flag.png") },
        { code: "+386", name: "Slovenia", flag: require("@/assets/si-flag.png") },
        { code: "+34", name: "Spain", flag: require("@/assets/es-flag.png") },
        { code: "+46", name: "Sweden", flag: require("@/assets/se-flag.png") },
        { code: "+41", name: "Switzerland", flag: require("@/assets/ch-flag.png") },
        { code: "+90", name: "Turkey", flag: require("@/assets/tr-flag.png") }
      ],
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
    validatePhone() {
      // Проверяем, чтобы номер телефона был непустым и содержал только цифры
      if (!this.userPhoneNumber || !/^\d+$/.test(this.userPhoneNumber)) {
        this.phoneError = true;
      } else {
        this.phoneError = false;
      }
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectCountry(country) {
      this.selectedCountryCode = country.code;
      this.selectedFlag = country.flag;
      this.isDropdownOpen = false;
    },
    validatePhoneNumber() {
      this.userPhoneNumber = this.userPhoneNumber.replace(/\D/g, ''); // Удаляет все символы, кроме цифр
    },
    getPhoneNumberMaxLength(countryCode) {
      // Определите максимальную длину номера телефона в зависимости от кода страны
      const maxLengthMap = {
        '+1': 10, // США
        '+44': 10, // Великобритания
        '+49': 10, // Германия
        '+7': 10, // Россия
        '+34': 9, // Испания
        // Добавьте другие страны по необходимости
      };
      return maxLengthMap[countryCode] || 10; // По умолчанию 10
    },
  validateEmail() {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailPattern.test(this.email)) {
      this.emailError = this.$t('InvalidEmail');
      return false; // Email невалиден
    } else {
      this.emailError = '';
      return true; // Email валиден
    }
  },   
   async contactus() {
      if (this.selectedFlag === require("@/assets/unknown-flag.svg")) {
        alert("Please select your country flag.");
        return;
      }
      this.validatePhone();
      this.nameError = !this.name;
      this.textError = !this.text;
      const isEmailValid = this.validateEmail();
      // Проверка на заполненность обязательных полей
      if (!this.name || !isEmailValid || !this.text || this.phoneError) {
        alert(this.$t('fillallreq'));
        return;
      }
  
      try {
        // Формируем данные для отправки
        const data = {
          name: this.name,
          email: this.email,
          phone: this.selectedCountryCode + this.userPhoneNumber,
          message: this.text,
        };
  
        // Отправляем запрос на сервер
        const response = await fetch("/api/sendContactInfo", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
  
        if (response.ok) {
          // Обработка успешного ответа
          alert(this.$t('Thanksforreq'));
          // Очищаем поля формы
          this.name = '';
          this.email = '';
          this.text = '';
          this.nameError = false;
          this.emailError = '';
          this.textError = false;
          this.phoneError = false;
          this.userPhoneNumber = '';
        } else {
          // Обработка ошибки
           alert(this.$t('Errorreq'));
        }
      } catch (error) {
        // Обработка ошибки сети или других проблем
        console.error("Ошибка при отправке сообщения:", error);
         alert(this.$t('Errorreq'));
      }
    },
     async subscribe() {
      if (!this.email) {
        alert("Please enter a valid email address.");
        return;
      }

      try {
        const response = await fetch("/api/subscribe", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: this.email }),
        });

        if (response.ok) {
          alert("Thank you for subscribing!");
          this.email = "";
        } else {
          alert("Subscription failed. Please try again later.");
        }
      } catch (error) {
        alert("An error occurred. Please try again later.");
      }
    },
    initMap() {
      const location = { lat: 39.4633, lng: -0.3757 }; // Координаты адреса в Валенсии
      const map = new google.maps.Map(document.getElementById("map"), {
        zoom: 15,
        center: location,
      });
    
      const icon = {
        url: require('@/assets/school.png'), // Путь к вашему изображению
        scaledSize: new google.maps.Size(50, 50), // Измените размер по необходимости
        origin: new google.maps.Point(0, 0), // Начальная точка изображения
        anchor: new google.maps.Point(25, 50), // Точка, соответствующая местоположению на карте
      };
    
      const marker = new google.maps.Marker({
        position: location,
        map: map,
        icon: icon, // Используем кастомное изображение как маркер
      });
    
      // Использование $t() для перевода текста в инфоокне
      const infoWindow = new google.maps.InfoWindow({
        content: `<p>${this.$t('clickToNavigate')}</p>`,
      });
    
      // Добавление обработчика события клика по маркеру
      marker.addListener('click', () => {
        window.open('https://www.google.com/maps/place//data=!4m2!3m1!1s0xd604fb5c4d26f0f:0x4c2e214c787f748?sa=X&ved=1t:8290&ictx=111', '_blank');
      });
    
      // Добавление обработчика события наведения на маркер (открытие инфоокна)
      marker.addListener('mouseover', () => {
        infoWindow.open(map, marker);
      });
    
      // Добавление обработчика события ухода с маркера (закрытие инфоокна)
      marker.addListener('mouseout', () => {
        infoWindow.close();
      });
    },
  },
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

body {
  margin: 0;
  padding: 0;
  font-family: 'DM Sans', sans-serif;
  overflow-x: hidden;
}

.contact-page {
  width: 100%;
}

h2 {
    font-size: 36px;
    margin-bottom: 20px;
    text-align: center;
    white-space: pre-wrap;
    font-weight: 500;
}
h3 {
    font-size: 30px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 400;
}
.header-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.map-container {
  width: 100%;
  height: 400px; /* Настройте высоту карты по вашему усмотрению */
  margin: 40px 0;
}

.input-error {
  border-color: red !important;
}

.error-message {
  color: red;
  font-size: 14px;
}            
.contact-form .custom-phone-input {
  width: 100%;
  padding: 16px;
  border: 2px solid #602b84;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  background-color: #fff;
  box-sizing: border-box;
  font-size: 16px;
  outline: none;
}


.country-code {
  margin-left: 8px;
  font-size: 14px;
  color: #333; /* Настройте цвет */
}
/* Стиль при фокусе */
.contact-form .custom-phone-input:focus {
  border-color: #602b84; /* Цвет бордера при фокусе */
}

/* Стиль при ошибке */
.input-error.custom-phone-input {
  border-color: red !important; /* Красный бордер при ошибке */
}

.input-container {
  display: flex;
  align-items: center;
  margin-top: 2%;
}

.custom-select-wrapper {
  position: relative;
  width: 10%;
  margin-right: -1px; /* Отступ между селектором страны и полем ввода */
}

.selected-option {
  display: flex;
  align-items: center;
  padding: 15px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border: 2px solid #602b84;
  background-color: white;
  cursor: pointer;
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #fff;
  border: 2px solid #602b84;
  max-height: 170px;
  overflow-y: auto;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
}

.option {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.with-divider {
  border-bottom: 1px solid #ddd;
}

.flag {
  width: 20px;
  height: 20px;
  margin-left: 10%;
}

.phone-input {
  width: 80%; /* Ширина поля ввода номера телефона */
  padding: 13px;
  border: 1px solid #ee322e;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  background-color: white;
  box-sizing: border-box;
}
              
.email-link {
  margin-left: 10px; /* Отступ от текста */
  color: #EE322E; /* Цвет ссылки */
  text-decoration: none;
}

.email-link:hover {
  text-decoration: underline;
}
              
.phone-link {
  margin-left: 10px; /* Отступ от текста */
  color: #602B84; /* Цвет ссылки */
  text-decoration: none;
}
.call-to-action {
  margin-top: 20px;
}

.call-to-action p {
  color: white;
  font-size: 18px;
  font-weight: 400; /* DM Sans Regular */
}

.phone-link:hover {
  text-decoration: underline;
}              
.background-section {
  width: 100%;
  height: 60vh; /* Высота 60% от высоты экрана */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
}
              
.map-container {
  width: 100%;
  height: 400px; /* Задайте нужную высоту */
  margin-top: 20px; /* Отступ сверху для отделения карты от формы */
}
.contact-content {
  background-color: none; /* Coral */
  color: black;
  padding: 40px;
  border-radius: 10px;
  max-width: 800px;
  margin: 40px auto 0 auto; /* Отступ сверху для размещения под фиксированным хедером */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-content h1 {
  text-align: center;
  font-size: 36px;
  margin-bottom: 20px;
}

.contact-form-container {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-form label {
  font-size: 16px;
}

.contact-form select,
.contact-form input {
  width: 100%;
  padding: 20px;
  border-radius: 50px;
  border: 2px solid #602b84;
  font-size: 16px;
  background-color: white;
  box-sizing: border-box;
}

.contact-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contact-info label {
  font-size: 16px;
}

.contact-info input {
  width: 100%;
  padding: 20px;
  border-radius: 50px;
  border: 2px solid #602b84;
  font-size: 16px;
  background-color: white;
  box-sizing: border-box;
}  
.contact-info textarea {
  width: 100%;
  padding: 20px;
  border-radius: 30px;
  border: 2px solid #602b84;
  font-size: 16px;
  background-color: white;
  box-sizing: border-box;
}  
.book-button {
  padding: 20px;
  background-color: #EE322E; /* Morado */
  color: white;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.book-button:hover {
  background-color: #FEDA3A; /* Darker Morado */
  color: black;
}
.newsletter-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(240, 244, 248, 0.7);
  padding: 40px;
  border-radius: 10px;
  width: 80%;
  margin: 0 auto;
}

.newsletter-content {
  flex: 1;
  padding-right: 20px;
}
              
.emailus-content {
  align-items: center;
}
.findus-content {
  align-items: center;
}              
.newsletter-input-container {
  flex: 2;
  display: flex;
  align-items: center;
}

.newsletter-input-container input {
  padding: 6px;
  border: 2px solid #333;
  border-radius: 50px 50px 50px 50px;
  flex: 1;
  height: 40px;
  padding-left: 25px;
  font-size: 16px;
  font-family: 'DM Sans', sans-serif;
}

.newsletter-input-container button {
  padding: 15px;
  border-radius: 50px 50px 50px 50px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 40px;
}

.newsletter-input-container button span {
  font-size: 20px;
  color: #333;
  font-family: 'DM Sans', sans-serif;
}


/* Адаптация для планшетов */
@media (max-width: 768px) {
  .background-section {
    height: 60hv;
  }
  .custom-select-wrapper {
    width: 10%;
  }

  .selected-option {
    padding: 15px;
    height: 20px;
  }

  .options {
    max-height: 150px;
  }

  .option {
    padding: 8px;
  }
  .call-to-action p {
    font-size: 18px;
  }
  .contact-content {
    padding: 40px;
    max-width: 600px;
    margin: -100px auto 55px auto;
  }
  .background-image {
    height: 50%;
  }
  .contact-content h1 {
    font-size: 28px;
  }
  .map-container {
    height: 250px; /* Еще более уменьшенная высота для мобильных устройств */
    margin: 20px 0;
  }
  .contact-form {
    gap: 15px;
  }
  .newsletter-section {
    flex-direction: column;
    align-items: stretch;
    padding: 20px;
    width: 100%;
  }
  h2 {
    font-size: 30px;
    margin-bottom: 20px;
    text-align: center;
  }
  h3 {
      font-size: 24px;
      margin-bottom: 20px;
      text-align: center;
  }
  .newsletter-content {
    flex: 1;
    padding-right: 0;
    margin-bottom: 20px;
  }

  .newsletter-input-container {
    flex: 1;
    display: flex;
    align-items: center;
    margin-bottom: 60px;
  }
  
  .newsletter-input-container input {
    height: 40px;
  }

  .newsletter-input-container button {
    height: 54px;
    margin-right: 10%;
  }            
}

/* Адаптация для мобильных устройств */
@media (max-width: 480px) {
  .background-section {
    height: 60hv;
  }
  .contact-content {
    padding: 40px;
    max-width: 100%;
  }
  .background-image {
    height: 50%;
  }
  .custom-select-wrapper {
    width: 23%;
  }
  .call-to-action p {
    font-size: 16px;
  }
  .selected-option {
    padding: 9px;
    height: 34px;
    font-size: 12px;
  }

  .options {
    max-height: 140px;
  }

  .option {
    padding: 7px;
    font-size: 12px;
  }
  h2 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }
  h3 {
      font-size: 18px;
      margin-bottom: 20px;
      text-align: center;
  }
  .contact-content h1 {
    font-size: 24px;
  }
  .map-container {
    height: 200px; /* Минимальная высота для очень маленьких экранов */
    margin: 15px 0;
  }
  .contact-form {
    gap: 10px;
  }

  .book-button {
    font-size: 14px;
    padding: 14px;
  }
  .newsletter-section {
    padding: 20px;
    width: 90%;
  }

  .newsletter-input-container input {
    height: 40px;
  }

  .newsletter-input-container button {
    height: 54px;
  }
}
</style>
